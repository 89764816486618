export interface Rule {
    text: string;
    description?: string;
    images?: RuleImage[];
    rules?: Rule[];
}

export interface RuleImage {
    text?: string;
    src: string;
}

export const ENROLLMENT_RULES: Rule[] = [
    {
        text: '신규등록',
        rules: [
            { text: '신규등록은 밀당PT 각 과목별 프로그램을 처음 이용하는 경우에 해당합니다.' },
            {
                text: "프로그램 구매 이후 과목별 ‘원생등록지'를 작성하여 제출하시면 밀당PT 신규 원생으로 등록됩니다.",
                rules: [
                    {
                        text: '원생등록지는 밀당PT 서비스를 이용하기 위해 필요한 정보(회원 기본정보, 진단고사 및 학습일정 등)를 확인하는 설문지입니다',
                    },
                    { text: '두 개 이상의 프로그램을 구매하신 경우, 과목별/학생별로 원생등록지를 구분하여 작성해야 합니다' },
                    { text: "신규 원생은 과목별로 ‘영어 신규원생', ‘수학 신규원생', ‘국어 신규원생'으로 구분합니다 " },
                ],
            },
            {
                text: '프로그램 구매내역이 확인되면 학부모님께 순차적으로 원생등록 절차를 안내드립니다.',
                rules: [{ text: '안내드리는 원생등록 마감기한까지 원생등록지가 제출되지 않을 경우, 과목별 진단고사 및 개강 일정이 연기될 수 있습니다.' }],
            },
        ],
    },
    {
        text: '연장등록',
        rules: [
            { text: '이용 프로그램의 교습기간을 동일 과목에 대하여 연장하는 경우에 해당합니다.' },
            { text: '교습종료 예정일을 기준으로 7일전까지 학부모님 연락처로 연장등록 안내문이 발송됩니다.' },
            { text: '안내드리는 결제 마감기한까지 연장등록이 진행되지 않을 시, 교습종료 예정일 다음날부터 교습이 중단됩니다.' },
            {
                text: '불가피한 사유로 결제 마감기한까지 결제가 어려우신 경우에는 담당 원장님과 사전에 상의하여 결제 마감기한을 7일 연장할 수 있으며, 해당 기간 동안 예정된 교습을 미리 진행하실 수 있습니다.',
                rules: [{ text: '교습 이후 결제를 진행하지 않을 시, 미수금이 청구됩니다' }],
            },
            {
                text: '미연장 또는 환불 등의 사유로 교습이 중단된 이후에는 재등록 절차를 통해 다시 교습을 재개할 수 있습니다',
                rules: [{ text: '교습종료 예정일 이후 결제 시, 결제 당일에는 교습 재개가 불가합니다.' }],
            },
        ],
    },
    {
        text: '재등록',
        rules: [
            {
                text: '이용이력이 있는 회원이 미연장 또는 환불 등의 사유로 교습이 종료된 이후 동일 과목을 다시 등록하는 경우에 해당합니다. 문의시점에 따라 등록 문의처 및 등록 절차는 아래와 같습니다',
            },
            {
                text: '교습종료일로부터 30일 이내',
                rules: [
                    {
                        text: '‘학습 재등록 신청서’를 통해 재등록 신청이 가능하며, 안내드리는 결제 마감기한까지 재등록이 진행되지 않을 시 교습 재개일이 연기될 수 있습니다',
                    },
                    { text: '상담 컨설턴트와의 신규 상담, 원생등록지 작성, 진단고사 응시 과정을 생략하여 빠른 교습 재개가 가능합니다' },
                    {
                        text: '직전 교습 시 안심환불 혜택이 제공된 경우 또는 직전 교습종료일을 기준으로 학년이 변경된 경우에는 상담 컨설턴트와의 신규 상담을 통해 재등록이 가능합니다.',
                    },
                ],
            },
            {
                text: '교습종료일로부터 30일 경과 후',
                rules: [
                    {
                        text: '상담 컨설턴트와의 신규 상담을 통해 재등록 신청이 가능하며, 안내드리는 원생등록 마감기한까지 원생등록지가 제출되지 않을 경우 진단고사 및 개강 일정이 연기될 수 있습니다.',
                    },
                    { text: '원생등록지 작성, 진단고사 응시 이후 교습 재개가 가능합니다. 단, 회원의 요청 시 진단고사는 응시하지 않을 수 있습니다.' },
                ],
            },
            { text: '이용이력이 있는 회원이 재등록하는 경우, 신규등록 혜택은 제공되지 않습니다.' },
            { text: '선생님이 변경될 수 있습니다.' },
        ],
    },
];

export const ASBSENT_RULES: Rule[] = [
    {
        text: '용어 정의',
        rules: [
            {
                text: '아래와 같이 용어를 구분합니다.',
                rules: [
                    { text: '휴원 : 학원의 요청으로 인해 교습을 진행하지 않고, 진행하지 않은 날만큼 교습 기간이 연장됩니다.' },
                    { text: '결석 : 회원이 교습에 임하지 않고, 출석 인정여부에 따라 출석인정 결석과 출석 미인정 결석으로 구분합니다.' },
                    { text: '출석 인정 결석에 한하여 미학습 분량을 스스로 수행할 수 있도록 나머지 학습이 제공됩니다.' },
                    {
                        text: '무단 결석 : 회원이 교습이 불가하다는 사실을 알리지 않았으며, 교습을 진행하지 않은 날에 대한 보상이 제공되지 않습니다.',
                    },
                    { text: '온택트 수업 : 선생님과 함께 당일 목표 학습량을 달성하는 것을 의미합니다.' },
                    { text: '나머지 학습 : 출석 인정 결석 시, 결석 당일 목표 학습량을 스스로 수행하는 것을 의미합니다.' },
                ],
            },
            { text: '결석이 필요한 일자를 기준으로 1영업일 전 22시까지 담당 원장님 또는 선생님께 요청해야 합니다.' },
            {
                text: '국가지정 공휴일(대체 공휴일 포함)은 서비스를 운영하지 않습니다.',
                rules: [{ text: '단, 주말 프로그램의 경우 국가지정 공휴일은 휴원으로 반영되어 서비스 이용 회차가 연장됩니다.' }],
            },

            { text: '교습 포기 시 결석 및 무단결석이 발생한 교습기간에 대한 교습비는 반환하지 않습니다.' },
        ],
    },
    {
        text: '분류',
        rules: [
            {
                text: '회원이 결석을 요청하는 경우, 요청의 타당성에 따라 아래와 같이 처리합니다.',
                rules: [
                    {
                        text: '출석 인정결석 사유(질병, 천재지변, 학교행사, 타과목 시험, 경조사- 본인 결혼, 가족생일, 사망)로 결석할 경우, 스스로 학습할 수 있는 나머지 학습이 제공됩니다.',
                        rules: [
                            {
                                text: '요청사유가 타당하더라도 구매한 프로그램 기간에 따라 최대로 인정가능한 출석 일수에 제한이 있습니다.',
                                rules: [
                                    { text: '4주 프로그램 : 최대 1일' },
                                    { text: '3개월 또는 12주 프로그램 : 최대 3일' },
                                    { text: '6개월 또는 24주 프로그램 : 최대 6일' },
                                    { text: '1년 또는 48주 프로그램 : 최대 12일' },
                                ],
                            },
                            { text: '필요에 따라 증빙서류를 요청할 수 있습니다.' },
                        ],
                    },
                    {
                        text: '출석 미인정 결석 사유(타 교육프로그램 이용 및 기타 사유)로 결석할 경우, 시험대비에 차질이 생기기 때문에 출석으로 인정하지 않습니다.',
                    },
                    {
                        text: '사전에 요청 없이 결석할 경우 무단결석으로 처리되며, 구매한 프로그램과 무관하게 무단결석일이 최대 3일을 초과할 경우 교습연장 불가 또는 기타 불이익이 있을 수 있습니다.',
                    },
                ],
            },
            {
                text: '학원이 교습을 진행하지 않는 경우는 아래와 같으며, 교습종료일이 해당 기간만큼 연장됩니다.',
                rules: [
                    {
                        text: '방학',
                        rules: [
                            {
                                text: '과목 및 학년별 25년도 방학일정은 아래와 같습니다. ',
                                rules: [
                                    {
                                        text: '영어/국어',
                                        rules: [
                                            {text: '초등~고2 : 25년 7월 28일 ~ 8월 1일'},
                                            {text: '고3/N수: 25년 11월 24일 ~ 11월 28일'}
                                        ]
                                    },
                                    {
                                        text: '수학',
                                        rules: [
                                            {text: '초등~고1 : 25년 7월 28일 ~ 8월 1일'},
                                            {text: '고2: 25년 12월 26일 ~ 26년 1월 1일'},
                                            {text: '고3/N수: 25년 11월 24일 ~ 11월 28일'},
                                        ]
                                    },
                                ]
                            },
                            { text: '주말 프로그램 및 단기 특강 프로그램의 경우, 위 방학일정과 관계 없이 교습이 정상적으로 진행됩니다.' },
                        ],
                    },
                    { text: '천재지변으로 인한 휴원' },
                ],
            },
        ],
    },
];

const search = new URLSearchParams(window.location.search);
const searchGet = (value: string) => search.get(value) ?? '';
const purchaseType = decodeURIComponent(searchGet('purchase_type'));

const isRefund14Days = [
    'HOME_SHOPPING_MIDDLE_MATH_1',
    'HOME_SHOPPING_MIDDLE_ENGLISH_1',
    'HOME_SHOPPING_HIGH_MATH_1',
    'HOME_SHOPPING_HIGH_ENGLISH_1',
    'HOME_SHOPPING_MIDDLE_MATH_3',
    'HOME_SHOPPING_MIDDLE_ENGLISH_3',
    'HOME_SHOPPING_HIGH_MATH_3',
    'HOME_SHOPPING_HIGH_ENGLISH_3',
    'HOME_SHOPPING_MIDDLE_MATH_1_WEEKEND',
    'HOME_SHOPPING_MIDDLE_ENGLISH_1_WEEKEND',
    'HOME_SHOPPING_HIGH_MATH_1_WEEKEND',
    'HOME_SHOPPING_HIGH_ENGLISH_1_WEEKEND',
    'HOME_SHOPPING_MIDDLE_MATH_3_WEEKEND',
    'HOME_SHOPPING_MIDDLE_ENGLISH_3_WEEKEND',
    'HOME_SHOPPING_HIGH_MATH_3_WEEKEND',
    'HOME_SHOPPING_HIGH_ENGLISH_3_WEEKEND',
].includes(purchaseType);

export const REFUND_RULES: Rule[] = [
    {
        text: '강좌 폐쇄, 또는 운영정지로 인한 반환사유에 해당하는 경우',
        description: '(제18조제2항제1호 및 제2호)',
        rules: [{ text: '강좌 제공 불가일 기준으로, 이미 납부한 교습비(수강료) 등을 일할 계산한 금액을 반환합니다.' }],
    },
    {
        text: '회원 본인 의사에 의한 교습 포기에 해당하는 경우',
        description: '(제18조제2항제3호)',
        rules: [
            { text: '정가를 기준으로 차감금액을 책정하여 반환금액을 결정합니다.' },
            {
                text: `[신규등록 프로모션] 신규 등록 후 개강일로부터 ${isRefund14Days ? 14 : 7}일 이내 교습 포기 시에는 교습비 전액(교재비 제외)을 반환합니다.`,
                rules: [
                    { text: '신규등록(1-1)은 해당 과목 이용이력이 없는 회원이 최초로 등록한 경우에 해당합니다' },
                    { text: '해당 과목 이용이력이 있는 회원이 교습 재개를 희망하여 재등록 한 경우는 프로모션이 중복적용되지 않습니다.' },
                    { text: '단기 특강의 경우 [신규등록 프로모션]이 적용되지 않습니다.' },
                ],
            },
            {
                text: '주간 프로그램 : 반환금액은 아래 기준에 따라 계산합니다. 3개월/6개월/1년 프로그램의 1개월은 30일로 간주하며,  4주/7주/12주/24주/48주/수능까지 프로그램의 1개월은 28일로 간주합니다',
                rules: [
                    {
                        text: '교습기간이 1개월 이내인 경우',
                        rules: [
                            { text: '교습시작 이전: 납부한 교습비의 전액 - 혜택 또는 할인 받은 금액' },
                            { text: '총 교습기간의 1/3 경과 전: 납부한 교습비의 2/3 해당액 - 혜택 또는 할인 받은 금액' },
                            { text: '총 교습기간의 1/2 경과 전: 납부한 교습비의 1/2 해당액 - 혜택 또는 할인 받은 금액' },
                            { text: '총 교습기간의 1/2 경과 후: 반환하지 않음' },
                        ],
                    },
                    {
                        text: '교습기간이 1개월을 초과하는 경우',
                        rules: [
                            { text: '교습시작 전: 납부한 교습비의 전액 - 혜택 또는 할인 받은 금액' },
                            {
                                text: '교습시작 후: (*반환사유가 발생한 해당월의 반환대상 교습비 등의 금액 + 나머지 월 교습비 등의 전액을 합산한 금액) - 혜택 또는 할인 받은 금액',
                                rules: [
                                    {
                                        text: '*반환사유가 발생한 해당월의 반환대상 교습비 등의 금액 : 교습기간이 1개월 이내인 경우의 기준에 따라 산출한 금액을 말함',
                                    },
                                ],
                            },
                            {
                                text: '단, 일할환불 프로모션(2022/10/29 ~ 2023/04/09 기간 내 결제)에 한하여 아래 기준 적용',
                                rules: [
                                    { text: '교습시작 전: 납부한 교습비의 전액 - 혜택 또는 할인 받은 금액' },
                                    {
                                        text: '교습시작 후: 일별 평균 교습비{(납부한 교습비의 전액-혜택 또는 할인 받은 금액)/총 교습기간} * 실제 교습기간(교습시작일로부터 교습 포기 의사를 밝힌 날까지 경과한 기간)',
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                text: '주말 프로그램 : 1개월은 28일로 간주하며, 1개월에 대한 교습시수는 8회입니다.',
                rules: [
                    {
                        text: '교습기간이 1개월 이내인 경우',
                        rules: [
                            { text: '교습시작 이전: 납부한 교습비의 전액 - 혜택 또는 할인 받은 금액' },
                            {
                                text: '총 교습시수의 1/3 경과 전 : 납부한 교습비의 2/3 해당액 - 혜택 또는 할인 받은 금액',
                            },
                            { text: '총 교습시수의 1/2 경과 전 : 납부한 교습비의 1/2 해당액 - 혜택 또는 할인 받은 금액' },
                            { text: '총 교습시수의 1/2 경과 후 : 반환하지 않음.' },
                        ],
                    },
                    {
                        text: '교습기간이 1개월을 초과하는 경우 ',
                        rules: [
                            { text: '교습시작 전: 납부한 교습비의 전액 - 혜택 또는 할인 받은 금액' },
                            {
                                text: '교습시작 후: (*반환사유가 발생한 해당월의 반환대상 교습비 등의 금액 + 나머지 월 교습비 등의 전액을 합산한 금액) - 혜택 또는 할인 받은 금액',
                                description:
                                    '반환사유가 발생한 해당월의 반환대상 교습비 등의 금액 : 교습기간이 1개월 이내인 경우의 기준에 따라 산출한 금액을 말함',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        text: '환불 접수 및 처리 안내',
        rules: [
            {
                text: '교습에 대한 환불 신청은 고객센터를 통해 가능하며, 교습 시작 여부에 따라서 아래와 같이 환불이 접수될 예정입니다.',
                rules: [
                    { text: '교습 시작 전 환불은 평균 5영업일 내 결제 취소 접수가 완료될 예정입니다.' },
                    {
                        text: '교습 시작 후 환불의 경우에는 환불 사유에 따라 원장 상담이 진행될 수 있으며, 원장 상담 후 최종 환불 접수가 완료됩니다.',
                    },
                ],
            },
            {
                text: '카드 결제의 경우 카드 결제 취소로 접수되며, 가상계좌 결제의 경우 입금으로 접수됩니다.',
                rules: [
                    {
                        text: '결제 취소 접수가 완료되면 결제 시 등록 연락처로 취소 문자가 자동 발송되며, 결제 취소 접수 이후 처리기간은 아래와 같습니다.',
                        rules: [
                            { text: '카드결제 : 평균 5영업일이 소요되며, 카드사별로 처리기간 및 방법 등은 상이합니다.' },
                            {
                                text: '가상계좌 : 평균 5영업일이 소요되며, tosspayments(토스페이먼츠) 또는 아이헤이트플라잉버그스(주)로 입금됩니다.',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        text: '관련 유의사항',
        rules: [
            { text: '교습기간이 종료된 경우에는 교습비를 반환하지 않습니다.' },
            {
                text: '환급 조건에 해당하는 프로그램에 대한 중도 환불이 발생하는 경우 환급 신청이 불가하며, 환급액 지급 이후 중도 환불이 발생하는 경우 환불금액에서 환급액이 차감됩니다.',
            },
            {
                text: '환급 프로모션 신청은 환급 지급 조건에 해당하는 프로그램의 교습종료일로부터 3개월(90일)까지 가능합니다.',
            },
            {
                text: '총 교습기간은 등록 당시 프로그램 별로 고지된 수강기간을 말하며, 반환금액의 산정은 개강일로부터 반환사유가 발생한 날까지 경과된 교습기간을 기준으로 합니다.',
                rules: [
                    {
                        text: '프로그램 별로 고지된 수강기간은 아래와 같습니다.',
                        rules: [
                            { text: '4주 프로그램 : 28일' },
                            { text: '7주 프로그램 : 49일' },
                            { text: '12주 프로그램 : 84일' },
                            { text: '24주 프로그램 : 168일' },
                            { text: '48주 프로그램 : 336일' },
                            { text: '3개월 프로그램 : 90일' },
                            { text: '6개월 프로그램 : 180일' },
                            { text: '1년 프로그램 : 365일' },
                            { text: '수능까지 프로그램 : 구매 프로그램에 명시된 주차별 수강기간을 제공하며, 1주는 7일입니다.' },
                        ],
                    },
                    {
                        text: '회사의 귀책이 아닌 사유(결석, 개인일정, 질병 등)로 교습에 참여하지 않은 경우, 교습기간이 경과한 것으로 간주하며 반환금액 산정 시 제외됩니다. ',
                    },
                ],
            },
            {
                text: '총 교습시수는 등록 당시 프로그램 별로 고지된 수강시수를 말하며, 반환금액의 산정은 개강일로부터 반환사유가 발생한 날까지 경과된 교습시수를 기준으로 합니다. 1주는 7일이며, 1주에 대한 교습시수는 2회입니다',
                rules: [
                    {
                        text: '회사의 귀책이 아닌 사유(결석, 개인일정, 질병 등)로 교습에 참여하지 않은 경우, 교습시수가 경과한 것으로 간주하여 반환금액 산정 시 제외됩니다',
                    },
                ],
            },
            {
                text: '추가적인 혜택이 제공된 특별기획상품의 경우, 부분환불 시 제공한 혜택(할인금액, 교습기간 연장 등)을 철회하고 각 강의가 속한 혜택적용 이전 시점(교습비, 교습기간)으로 환불금액을 산정할 수 있습니다.',
            },
            { text: '환불 시 교습기간 중 이용한 커리큘럼의 종류와 제공받은 교습자료에 따라 교습기간에 따른 환불액이 다르게 책정될 수 있습니다.' },
            {
                text: '프로그램 구매 시 지급된 이벤트 상품/교재가 있을 경우, 해당 상품/교재 회수 후 환불 가능합니다.(제공된 상품/교재의 사용 혹은 반송 불가시, 상품/교재 정가금액 차감)',
            },
            {
                text: '사용 시 해당 교재 가격은 공제되며 배송비 환불은 불가합니다. 단, 교재 반송 시 내/외부 사용 및 훼손이 없는 미사용 교재임이 확인된 경우 전액환불 가능합니다.(반송비 본인 부담)',
            },
            {
                text: '양도 또는 아이디 공유 적발 시 회원 자격이 박탈되며, 환불 불가함을 원칙으로 합니다.',
                rules: [{ text: '추가적인 불법 공유 행위 적발 시 형사 고발 조치가 진행될 수 있습니다.' }],
            },
        ],
    },
];

export const REFUND_RULES_FOR_TEXTBOOK: Rule[] = [
    {
        text: '강좌 폐쇄, 또는 운영정지로 인한 반환사유에 해당하는 경우',
        rules: [{ text: '강좌 제공 불가일 기준으로, 이미 납부한 교재비 중 교재를 받지 못한 부분에 대한 금액을 전액 반환합니다.' }],
    },
    {
        text: '회원 본인의사로 교습 포기에 해당하는 경우',
        rules: [{ text: '결제액을 기준으로 ‘교재 배송을 받지 못한 금액’에 대해 반환을 진행합니다.' }],
    },
    {
        text: '환불 접수 및 처리 안내',
        rules: [
            {
                text: '교습에 대한 환불 신청은 고객센터를 통해 가능하며, 개강여부에 따라서 아래 순서로 환불이 진행될 예정입니다.',
                rules: [
                    { text: '교습 시작 전 환불은 영업일 기준 평균 5일 내로 결제 취소 접수가 완료될 예정입니다.' },
                    {
                        text: '교습 도중 환불의 경우에는 환불 사유에 따라 담당 원장님 상담이 진행될 예정이며, 원장 상담 후 최종 환불 접수가 완료됩니다.',
                    },
                ],
            },
            {
                text: '카드 결제의 경우 카드 결제 취소로 접수되며, 가상계좌 결제의 경우 입금으로 접수됩니다.',
                rules: [
                    {
                        text: '결제 취소 접수가 완료되면 결제 시 등록 연락처로 취소 문자가 자동 발송되며, 결제 취소 접수 이후 처리기간은 아래와 같습니다.',
                        rules: [
                            { text: '카드결제 : 영업일 기준 평균 5일이 소요될 예정이며, 카드사별로 처리기간 및 방법 등은 상이합니다.' },
                            {
                                text: '가상계좌 : 영업일 기준 평균 5일이 소요되며 tosspayments(토스페이먼츠) 또는 아이헤이트플라잉버그스(주)로 입금됩니다.',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        text: '관련 유의사항',
        rules: [
            {
                text: '밀당PT 내신 교재는 ‘학생 맞춤 교재’로 학생의 범위와 수준에 맞춰 제작합니다. 교재 재사용은 불가하기에 ‘제작 및 배송 시작’ 후에는 환불이 불가합니다.',
            },
        ],
    },
];

export const REFUND_RULES_FOR_ENVELOPE: Rule[] = [
    {
        text: '강좌 폐쇄, 또는 운영정지로 인한 반환사유에 해당하는 경우',
        rules: [{ text: '강좌 제공 불가일 기준으로, 이미 납부한 교재비 중 교재를 받지 못한 부분에 대한 금액을 전액 반환합니다.' }],
    },
    {
        text: '회원 본인의사로 교습 포기에 해당하는 경우',
        rules: [{ text: '결제액을 기준으로 ‘교재 배송을 받지 못한 금액’에 대해 반환을 진행합니다.' }],
    },
    {
        text: '환불 접수 및 처리 안내',
        rules: [
            {
                text: '교습에 대한 환불 신청은 고객센터를 통해 가능하며, 개강여부에 따라서 아래 순서로 환불이 진행될 예정입니다.',
                rules: [
                    { text: '교습 시작 전 환불은 영업일 기준 평균 5일 내로 결제 취소 접수가 완료될 예정입니다.' },
                    {
                        text: '교습 도중 환불의 경우에는 환불 사유에 따라 담당 원장님 상담이 진행될 예정이며, 원장 상담 후 최종 환불 접수가 완료됩니다.',
                    },
                ],
            },
            {
                text: '카드 결제의 경우 카드 결제 취소로 접수되며, 가상계좌 결제의 경우 입금으로 접수됩니다.',
                rules: [
                    {
                        text: '결제 취소 접수가 완료되면 결제 시 등록 연락처로 취소 문자가 자동 발송되며, 결제 취소 접수 이후 처리기간은 아래와 같습니다.',
                        rules: [
                            { text: '카드결제 : 영업일 기준 평균 5일이 소요될 예정이며, 카드사별로 처리기간 및 방법 등은 상이합니다.' },
                            {
                                text: '가상계좌 : 영업일 기준 평균 5일이 소요되며 tosspayments(토스페이먼츠) 또는 아이헤이트플라잉버그스(주)로 입금됩니다.',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        text: '관련 유의사항',
        rules: [
            {
                text: '밀당PT 봉투모의고사 교재는 ‘올해 6월, 9월 모의고사 및 작년 수능 출제경향을 반영하여’ 맞춤으로 제작합니다. 교재 재사용은 불가하기에 ‘제작 및 배송 시작’ 후에는 환불이 불가합니다.',
            },
        ],
    },
];

export const TABLET_RENTAL_RULES: Rule[] = [
    {
        text: '대여 목적',
        rules: [
            { text: '대여 서비스의 목적물인 태블릿PC의 제품명과 가격은 아래 고지된 바와 같습니다. 공부중독반 정책에 따라 제품이 변동될 수 있습니다.' },
            { text: '태블릿PC 제품명 : 아이패드 10.2 9세대 64GB + 애플펜슬 1세대' },
            { text: '태블릿PC 제품 가격 : 648,000원 (아이패드 499,000원 / 애플펜슬 1세대 149,000원)' },
        ],
    },
    {
        text: '대여 비용',
        rules: [{ text: '공부중독반 태블릿PC 4주 기준 대여금은 60,000원이며, 사용 시작 시점에 전액 결제해야 합니다.' }],
    },
    {
        text: '대여 기간',
        rules: [
            { text: '대여 기간은 고객이 제품을 수령한 날로부터, 공부중독반 수업이 종료되는 날까지 입니다.' },
            {
                text: '공부중독반 서비스를 중도 해지하더라도, 태블릿PC는 해지한 해당 기수의 공부중독반 학습 기간 동안 사용해야 하며, 해당 기수 시작 시점에 결제한 대여금은 반환되지 않습니다.',
            },
            {
                text: '해당 기수 공부중독반 수업이 정상종료된 후에 연속되는 다음 기수 공부중독반 수업을 유료결제하여 수강하는 고객은 대여기간이 다음 기수 수업기간만큼 연장되며, 태블릿을 계속 사용할 수 있습니다.',
            },
        ],
    },
    {
        text: '제품 반납',
        rules: [
            {
                text: '고객은 수업 종료일로부터 영업일 5일 내에 회사가 지정한 배송 업체에 반납해야 하며, 수업 종료일로부터 영업일 2-3일 내에 택배사로부터 연락을 받은 후 반납을 진행해야 합니다.(별도의 연락이 없을 경우, 안내되는 회사의 주소지로 반환합니다.)',
            },
            { text: '고객은 구성품 전체를 상자에 담아 수령 시 받았던 상태 그대로 반납해야 합니다.' },
            { text: '고객이 영업일 10일을 경과하여도 태블릿을 반납하지 않는 경우, 지연일 수 X 태블릿 하루 대여료 2,000원을 배상해야 합니다.' },
            { text: '고객이 한 달 이상 제품을 반납하지 아니하고, 연락을 받지 않는 경우 회사는 제품 가격 전액에 대하여 고객에게 청구권을 행사할 수 있습니다' },
        ],
    },
    {
        text: '제품의 하자',
        rules: [
            {
                text: '고객은 회사로부터 제품 수령 시 하자 유무를 검사해야 하며, 하자가 있다면 제품 수령일로부터 영업일 기준 3일 내에 해당 사실을 회사에게 알리고 교환을 요구할 수 있습니다. 교환 비용은 회사가 부담합니다.',
            },
        ],
    },
    {
        text: '제품의 손해배상',
        rules: [
            {
                text: '제 4-5조의 사유에 해당하지 않고, 고객의 귀책 사유로 제품에 손상, 장애, 분실 등의 제품 가치를 훼손하는 경우 수리 비용 및 자산 피해에 대해서는 고객이 부담하거나 회사에 전액 보상해야 합니다.',
            },
            {
                text: '고객의 과실로 인해 제품을 수리할 경우 제조사의 공식 서비스 센터를 이용해야 하며, 그 외 사설업체를 이용하는 것은 금지됩니다. (사설 수리점에서 수리한 경우 제품을 반납할 수 없으며, 제품 가격 전액을 회사에 변상해야 합니다.)',
            },
        ],
    },
    {
        text: '대여 서비스 중도 해지 및 환불',
        rules: [
            { text: '공부중독반 서비스를 중도 해지하여 환불을 받더라도 태블릿PC 대여 비용은 반환하지 않습니다.' },
            { text: '단, 교습시작일로부터 일주일간의 공부중독반 체험기간 동안은 태블릿PC 대여 비용도 전액 환불 됩니다.' },
            { text: '교습시작일로부터 일주일이 지난 후에는 환불이 불가능하며, 해당 기수 공부중독반 수업 최초 약정 기간동안 사용해야 합니다.' },
            {
                text: '교습시작일 전에는 태블릿PC가 미리 도착하더라도, 제품을 개봉하면 안 됩니다. 교습시작일 전에 제품을 개봉한 후 환불을 요청할 시에는 대여금은 환불이 안 되며 약정한 해당 기수 공부중독반 수업 약정기간동안 고객이 사용해야 합니다.',
            },
        ],
    },

    {
        text: '태블릿PC의 무상 지급',
        rules: [
            { text: '고객이 12개월 동안 연속해서 공부중독반 서비스를 이용하는 경우 고객은 대여한 태블릿을 무상으로 취득할 자격을 갖게 됩니다.' },
            {
                text: '고객이 과거에 이미 본조에 따라 태블릿 PC 등을 무상 지급 받은 경우에는 태블릿 PC 등을 새로 임차하더라도 본조에 따라 태블릿 PC 등을 무상 지급 받을 수 없습니다.',
            },
        ],
    },
];

export const PRODUCT_RULES: Rule[] = [
    {
        text: '등록',
        rules: ENROLLMENT_RULES,
    },
    {
        text: '결석/휴원',
        images: [{ text: '[참고] 결석/휴원 기준', src: 'images/payment/absent_policy.png' }],
        rules: ASBSENT_RULES,
    },
    {
        text: '환불',
        description: '*「학원의 설립·운영 및 과외교습에 관한 법률 시행령」에 근거한 환불조항입니다.',
        images: [{ text: '[참고] 반환금액 기준', src: 'images/payment/refund_policy.png' }],
        rules: REFUND_RULES,
    },
];

export const PRODUCT_RULES_FOR_ACORDIAN: Rule[] = [
    {
        text: '밀당PT 등록 안내',
        rules: ENROLLMENT_RULES,
    },
    {
        text: '결석 및 휴원 관련 규정 안내',
        images: [{ text: '[참고] 결석/휴원 기준', src: 'images/payment/absent_policy.png' }],
        rules: ASBSENT_RULES,
    },
    {
        text: '환불 및 관련 유의사항 안내',
        description: '*「학원의 설립·운영 및 과외교습에 관한 법률 시행령」에 근거한 환불조항입니다.',
        images: [{ text: '[참고] 반환금액 기준', src: 'images/payment/refund_policy.png' }],
        rules: REFUND_RULES,
    },
];

export const PRODUCT_RULES_FOR_ACORDIAN_WITH_TABLET: Rule[] = [
    {
        text: '밀당PT 등록 안내',
        rules: ENROLLMENT_RULES,
    },
    {
        text: '결석 및 휴원 관련 규정 안내',
        images: [{ text: '[참고] 결석/휴원 기준', src: 'images/payment/absent_policy.png' }],
        rules: ASBSENT_RULES,
    },
    {
        text: '환불 및 관련 유의사항 안내',
        description: '*「학원의 설립·운영 및 과외교습에 관한 법률 시행령」에 근거한 환불조항입니다.',
        images: [{ text: '[참고] 반환금액 기준', src: 'images/payment/refund_policy.png' }],
        rules: REFUND_RULES,
    },
    {
        text: '태블릿PC 대여 서비스 이용 안내',
        description: '아이헤이트플라잉버그스㈜ (이하 ‘회사’)는 아래와 같이 태블릿PC를 밀당PT 공부중독반 고객(이하 ‘고객’)에게 임대하고 회원은 이를 임차합니다.',
        rules: TABLET_RENTAL_RULES,
    },
];

export const PRODUCT_RULES_FOR_TEXTBOOK: Rule[] = [
    {
        text: '환불 및 관련 유의사항 안내',
        rules: REFUND_RULES_FOR_TEXTBOOK,
    },
];

export const PRODUCT_RULES_FOR_ENVELOPE: Rule[] = [
    {
        text: '환불 및 관련 유의사항 안내',
        rules: REFUND_RULES_FOR_ENVELOPE,
    },
];

