import { Button } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { PaymentPageType } from '../../constants/payment';
import { ViewType } from '../../constants/payment-page';
import { changeCurrencyUnit, getDiscountedPrice } from '../../utils/currency';

const Wrapper = styled.div`
    display: inline-block;
    border: 4px solid #ffffff;
    background-image: url('/images/payment/product_card.jpg');
    background-size: contain;
    box-shadow:
        rgba(0, 0, 0, 0.12) 0px 1px 8px,
        rgba(0, 0, 0, 0.12) 0px 1px 6px;
    cursor: pointer;
    width: ${(props) => (props.isMobile ? '90%' : '48.1%')};
    margin: ${(props) => (props.isMobile ? '0% 5% 5% 5%' : '1%')};
    border-radius: ${(props) => (props.isMobile ? '5px' : '10px')};
    flex-grow: 1;
`;

export const ProductCardMegaStudy = (props) => {
    const { isMobile, contentWidth, paymentType, viewType } = props;
    const cardWidth = ((isMobile, contentWidth) => {
        if (isMobile) return contentWidth * 3.1;
        return contentWidth;
    })(isMobile, contentWidth);

    const isHighGrade3Megastudy = viewType === ViewType.GRADE_3;

    return (
        <Wrapper isMobile={isMobile}>
            <div
                style={{
                    width: '100%',
                    padding: '5% 10%',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        minHeight: cardWidth * 10.5,
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                        <div>
                            <span
                                style={{
                                    fontSize: cardWidth * 1.5,
                                    fontWeight: 'bold',
                                }}
                            >
                                {isHighGrade3Megastudy ? '고3 메가PT' : '[선택1] 메가PT (메가패스 이용자 전용 PT)'}
                            </span>
                        </div>
                        <div
                            style={{
                                fontSize: cardWidth * 1.1,
                                color: 'grey',
                            }}
                        >
                            월 최소 결제금액 35만원
                        </div>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                            flexDirection: 'column',
                            gap: '8px',
                        }}
                    >
                        <Button
                            color={'primary'}
                            variant={'contained'}
                            style={{
                                width: '100%',
                                height: cardWidth * 2,
                                position: 'relative',
                                fontSize: cardWidth * 1,
                            }}
                            onClick={() => global.open('https://mildang.kr/mega-landing03/')}
                        >
                            상담하러 가기
                        </Button>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'baseline',
                            flexDirection: 'row-reverse',
                        }}
                    >
                        <div
                            style={{
                                fontSize: cardWidth * 1.8,
                                fontWeight: 'bold',
                                color: 'hotpink',
                            }}
                        >
                            최소 {changeCurrencyUnit(1060000)}원
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};
