import { PurchaseType } from './purchase-page';

export enum PaymentDirectType {
    DEFAULT = 'DEFAULT',
    ELEMENTARY = 'ELEMENTARY',
    ELEMENTARY_NEW = 'ELEMENTARY_NEW',
    ELEMENTARY_NEW_WEEKEND = 'ELEMENTARY_NEW_WEEKEND',
    ELEMENTARY_PROMOTION = 'ELEMENTARY_PROMOTION',
    ELEMENTARY_PROMOTION_WEEKEND = 'ELEMENTARY_PROMOTION_WEEKEND',
    HOME_SHOPPING = 'HOME_SHOPPING',
    HOME_SHOPPING_WEEKEND = 'HOME_SHOPPING_WEEKEND',
    ADDICTIVE = 'ADDICTIVE',
    ADDICTIVE_4 = 'ADDICTIVE_4',
    ADDICTIVE_30 = 'ADDICTIVE_30',
    ADDICTIVE_SUMMER_VACATION = 'ADDICTIVE_SUMMER_VACATION',
    ADDICTIVE_COMPRESSED = 'ADDICTIVE_COMPRESSED',
    ADDICTIVE_MONTHLY_BILLING = 'ADDICTIVE_MONTHLY_BILLING',
    ADDICTIVE_COMPRESSED_MONTHLY_BILLING = 'ADDICTIVE_COMPRESSED_MONTHLY_BILLING',
    ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_MONTHLY_BILLING = 'ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_MONTHLY_BILLING',
    ADDICTIVE_MIDDLE_SCHOOL_MATH_MONTHLY_BILLING = 'ADDICTIVE_MIDDLE_SCHOOL_MATH_MONTHLY_BILLING',
    ADDICTIVE_HIGH_SCHOOL_ENGLISH_MONTHLY_BILLING = 'ADDICTIVE_HIGH_SCHOOL_ENGLISH_MONTHLY_BILLING',
    ADDICTIVE_HIGH_SCHOOL_MATH_MONTHLY_BILLING = 'ADDICTIVE_HIGH_SCHOOL_MATH_MONTHLY_BILLING',
}

export enum PaymentDirectDisplayType {
    DEFAULT = 'DEFAULT',
    ELEMENTARY = 'ELEMENTARY',
    ADDICTIVE = 'ADDICTIVE',
    MONTHLY_BILLING = 'MONTHLY_BILLING',
    PURCHASE = 'PURCHASE',
}

export interface IPaymentDirectPage {
    type: PaymentDirectType;
    displayType: PaymentDirectDisplayType;
    path: string;
    purchases: PurchaseType[];
    images?: IPaymentImage[];
    isPromotion?: boolean;
}

export enum ImageType {
    DESKTOP = 'desktop',
    MOBILE = 'mobile',
}

export interface IPaymentImage {
    name: string;
    url: {
        [ImageType.DESKTOP]: string;
        [ImageType.MOBILE]: string;
    };
}

export const getPaymentDirectPage = (paymentDirectType: PaymentDirectType) => {
    return PaymentDirectPages.find((page) => page.type === paymentDirectType);
};
export const getPaymentDirectPageByPurchaseType = (purchaseType: PurchaseType) => {
    return PaymentDirectPages.find((page) => page.purchases.find((purhcase) => purhcase === purchaseType));
};
export const getPaymentDirectImage = (page: IPaymentDirectPage, name: string, type: ImageType): string => {
    const image = page.images?.find((image) => image.name === name)?.url[type] ?? '';
    return image;
};
export const PaymentDirectPages: IPaymentDirectPage[] = [
    {
        type: PaymentDirectType.DEFAULT,
        displayType: PaymentDirectDisplayType.DEFAULT,
        path: '/direct',
        purchases: [PurchaseType.MIDDLE_MATH, PurchaseType.MIDDLE_ENGLISH, PurchaseType.HIGH_MATH, PurchaseType.HIGH_ENGLISH],
    },
    {
        type: PaymentDirectType.ELEMENTARY_NEW,
        displayType: PaymentDirectDisplayType.ELEMENTARY,
        path: '/direct/elementary/new',
        purchases: [PurchaseType.ELEMENTARY_NEW_ENGLISH, PurchaseType.ELEMENTARY_NEW_MATH, PurchaseType.ELEMENTARY_NEW_KOREAN],
    },
    {
        type: PaymentDirectType.ELEMENTARY_NEW_WEEKEND,
        displayType: PaymentDirectDisplayType.ELEMENTARY,
        path: '/direct/elementary/weekend/new',
        purchases: [PurchaseType.ELEMENTARY_NEW_ENGLISH_WEEKEND, PurchaseType.ELEMENTARY_NEW_MATH_WEEKEND],
    },
    {
        type: PaymentDirectType.ELEMENTARY_PROMOTION,
        displayType: PaymentDirectDisplayType.ELEMENTARY,
        path: '/direct/elementary/promotion',
        isPromotion: true,
        purchases: [PurchaseType.ELEMENTARY_PROMOTION_ENGLISH, PurchaseType.ELEMENTARY_PROMOTION_MATH, PurchaseType.ELEMENTARY_PROMOTION_KOREAN],
    },
    {
        type: PaymentDirectType.ELEMENTARY_PROMOTION_WEEKEND,
        displayType: PaymentDirectDisplayType.ELEMENTARY,
        path: '/direct/elementary/weekend/promotion',
        isPromotion: true,
        purchases: [PurchaseType.ELEMENTARY_PROMOTION_ENGLISH_WEEKEND, PurchaseType.ELEMENTARY_PROMOTION_MATH_WEEKEND],
    },
    {
        type: PaymentDirectType.HOME_SHOPPING,
        displayType: PaymentDirectDisplayType.DEFAULT,
        path: '/direct/home-shopping',
        purchases: [
            PurchaseType.HOME_SHOPPING_MIDDLE_MATH,
            PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH,
            PurchaseType.HOME_SHOPPING_HIGH_MATH,
            PurchaseType.HOME_SHOPPING_HIGH_ENGLISH,
        ],
    },
    {
        type: PaymentDirectType.HOME_SHOPPING_WEEKEND,
        displayType: PaymentDirectDisplayType.DEFAULT,
        path: '/direct/home-shopping/weekend',
        purchases: [
            PurchaseType.HOME_SHOPPING_MIDDLE_MATH_WEEKEND,
            PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_WEEKEND,
            PurchaseType.HOME_SHOPPING_HIGH_MATH_WEEKEND,
            PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_WEEKEND,
        ],
    },
    {
        type: PaymentDirectType.ADDICTIVE,
        displayType: PaymentDirectDisplayType.ADDICTIVE,
        path: '/direct/addictive',
        purchases: [PurchaseType.ADDICTIVE_MIDDLE_MATH, PurchaseType.ADDICTIVE_MIDDLE_ENGLISH],
        images: [
            {
                name: 'header',
                url: {
                    desktop: '/images/payment-direct/main/desktop/2-addictive.png',
                    mobile: '/images/payment-direct/main/mobile/2-addictive.png',
                },
            },
            {
                name: 'text',
                url: {
                    desktop: '/images/payment-direct/main/desktop/3-addictive-text.png',
                    mobile: '/images/payment-direct/main/mobile/3-addictive-text.png',
                },
            },
        ],
    },
    {
        type: PaymentDirectType.ADDICTIVE_4,
        displayType: PaymentDirectDisplayType.ADDICTIVE,
        path: '/direct/addictive/4',
        purchases: [PurchaseType.ADDICTIVE_MIDDLE_MATH_4, PurchaseType.ADDICTIVE_MIDDLE_ENGLISH_4],
        images: [
            {
                name: 'header',
                url: {
                    desktop: '/images/payment-direct/main/desktop/2-addictive-4.png',
                    mobile: '/images/payment-direct/main/mobile/2-addictive-4.png',
                },
            },
            {
                name: 'text',
                url: {
                    desktop: '/images/payment-direct/main/desktop/3-addictive-4-text.png',
                    mobile: '/images/payment-direct/main/mobile/3-addictive-4-text.png',
                },
            },
        ],
    },
    {
        type: PaymentDirectType.ADDICTIVE_30,
        displayType: PaymentDirectDisplayType.ADDICTIVE,
        path: '/direct/addictive/30',
        purchases: [PurchaseType.ADDICTIVE_MIDDLE_MATH_30, PurchaseType.ADDICTIVE_MIDDLE_ENGLISH_30],
        images: [
            {
                name: 'header',
                url: {
                    desktop: '/images/payment-direct/main/desktop/2-addictive-30.png',
                    mobile: '/images/payment-direct/main/mobile/2-addictive-30.png',
                },
            },
            {
                name: 'text',
                url: {
                    desktop: '/images/payment-direct/main/desktop/3-addictive-30-text.png',
                    mobile: '/images/payment-direct/main/mobile/3-addictive-30-text.png',
                },
            },
        ],
    },
    {
        type: PaymentDirectType.ADDICTIVE_SUMMER_VACATION,
        displayType: PaymentDirectDisplayType.ADDICTIVE,
        path: '/direct/addictive/summer-vacation',
        purchases: [PurchaseType.ADDICTIVE_MIDDLE_MATH_SUMMER_VACATION, PurchaseType.ADDICTIVE_MIDDLE_ENGLISH_SUMMER_VACATION],
        images: [
            {
                name: 'header',
                url: {
                    desktop: '/images/payment-direct/main/desktop/2-addictive-summer-vacation.png',
                    mobile: '/images/payment-direct/main/mobile/2-addictive-summer-vacation.png',
                },
            },
            {
                name: 'text',
                url: {
                    desktop: '/images/payment-direct/main/desktop/3-addictive-summer-vacation-text.png',
                    mobile: '/images/payment-direct/main/mobile/3-addictive-summer-vacation-text.png',
                },
            },
        ],
    },
    {
        type: PaymentDirectType.ADDICTIVE_COMPRESSED,
        displayType: PaymentDirectDisplayType.ADDICTIVE,
        path: '/direct/addictive/compressed',
        purchases: [PurchaseType.ADDICTIVE_MATH_COMPRESSED, PurchaseType.ADDICTIVE_ENGLISH_COMPRESSED],
        images: [
            {
                name: 'header',
                url: {
                    desktop: '/images/payment-direct/main/desktop/2-addictive-30.png',
                    mobile: '/images/payment-direct/main/mobile/2-addictive-30.png',
                },
            },
            {
                name: 'text',
                url: {
                    desktop: '/images/payment-direct/main/desktop/3-addictive-30-text.png',
                    mobile: '/images/payment-direct/main/mobile/3-addictive-30-text.png',
                },
            },
        ],
    },
    {
        type: PaymentDirectType.ADDICTIVE_MONTHLY_BILLING,
        displayType: PaymentDirectDisplayType.MONTHLY_BILLING,
        path: '/direct/addictive/monthly-billing',
        purchases: [PurchaseType.ADDICTIVE_MONTHLY_BILLING],
    },
    {
        type: PaymentDirectType.ADDICTIVE_COMPRESSED_MONTHLY_BILLING,
        displayType: PaymentDirectDisplayType.MONTHLY_BILLING,
        path: '/direct/addictive/compressed/monthly-billing',
        purchases: [PurchaseType.ADDICTIVE_COMPRESSED_MONTHLY_BILLING],
    },
    {
        type: PaymentDirectType.ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_MONTHLY_BILLING,
        displayType: PaymentDirectDisplayType.MONTHLY_BILLING,
        path: '/direct/addictive/middle-school/english/monthly-billing',
        purchases: [PurchaseType.ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_MONTHLY_BILLING],
    },
    {
        type: PaymentDirectType.ADDICTIVE_MIDDLE_SCHOOL_MATH_MONTHLY_BILLING,
        displayType: PaymentDirectDisplayType.MONTHLY_BILLING,
        path: '/direct/addictive/middle-school/math/monthly-billing',
        purchases: [PurchaseType.ADDICTIVE_MIDDLE_SCHOOL_MATH_MONTHLY_BILLING],
    },
    {
        type: PaymentDirectType.ADDICTIVE_HIGH_SCHOOL_ENGLISH_MONTHLY_BILLING,
        displayType: PaymentDirectDisplayType.MONTHLY_BILLING,
        path: '/direct/addictive/high-school/english/monthly-billing',
        purchases: [PurchaseType.ADDICTIVE_HIGH_SCHOOL_ENGLISH_MONTHLY_BILLING],
    },
    {
        type: PaymentDirectType.ADDICTIVE_HIGH_SCHOOL_MATH_MONTHLY_BILLING,
        displayType: PaymentDirectDisplayType.MONTHLY_BILLING,
        path: '/direct/addictive/high-school/math/monthly-billing',
        purchases: [PurchaseType.ADDICTIVE_HIGH_SCHOOL_MATH_MONTHLY_BILLING],
    },
    {
        type: PaymentDirectType.DEFAULT,
        displayType: PaymentDirectDisplayType.MONTHLY_BILLING,
        path: '/direct/high-math-7-hour',
        purchases: [PurchaseType.HIGH_MATH_7_HOUR],
    },
    {
        type: PaymentDirectType.DEFAULT,
        displayType: PaymentDirectDisplayType.MONTHLY_BILLING,
        path: '/direct/high-math-120-minute',
        purchases: [PurchaseType.HIGH_MATH_120_MINUTE],
    },
    {
        type: PaymentDirectType.DEFAULT,
        displayType: PaymentDirectDisplayType.MONTHLY_BILLING,
        path: '/direct/high-grade-3-math-120-minute',
        purchases: [PurchaseType.HIGH_GRADE_3_MATH_120_MINUTE],
    },
];
