import { produce } from 'immer';
import {
    IQuestionAnswerItem,
    questionListDefault,
    questionListHighSchool1,
    questionListHighSchool2,
    questionListHighSchool2Math,
    questionListHighSchool3,
    questionListHighSchool3Math,
    questionListMiddleSchool,
    questionListRefundTwoWeek,
} from '../components/purchase/section/cosntants/questionList';

export enum PurchaseType {
    ELEMENTARY_ENGLISH = 'ELEMENTARY_ENGLISH',
    ELEMENTARY_MATH = 'ELEMENTARY_MATH',
    ELEMENTARY_KOREAN = 'ELEMENTARY_KOREAN',
    ELEMENTARY_NEW_ENGLISH = 'ELEMENTARY_NEW_ENGLISH',
    ELEMENTARY_NEW_MATH = 'ELEMENTARY_NEW_MATH',
    ELEMENTARY_NEW_KOREAN = 'ELEMENTARY_NEW_KOREAN',
    ELEMENTARY_NEW_ENGLISH_WEEKEND = 'ELEMENTARY_NEW_ENGLISH_WEEKEND',
    ELEMENTARY_NEW_MATH_WEEKEND = 'ELEMENTARY_NEW_MATH_WEEKEND',
    ELEMENTARY_PROMOTION_ENGLISH = 'ELEMENTARY_PROMOTION_ENGLISH',
    ELEMENTARY_PROMOTION_MATH = 'ELEMENTARY_PROMOTION_MATH',
    ELEMENTARY_PROMOTION_KOREAN = 'ELEMENTARY_PROMOTION_KOREAN',
    ELEMENTARY_PROMOTION_ENGLISH_WEEKEND = 'ELEMENTARY_PROMOTION_ENGLISH_WEEKEND',
    ELEMENTARY_PROMOTION_MATH_WEEKEND = 'ELEMENTARY_PROMOTION_MATH_WEEKEND',
    MIDDLE_MATH = 'MIDDLE_MATH',
    MIDDLE_ENGLISH = 'MIDDLE_ENGLISH',
    HIGH_MATH = 'HIGH_MATH',
    HIGH_ENGLISH = 'HIGH_ENGLISH',
    HOME_SHOPPING_MIDDLE_MATH = 'HOME_SHOPPING_MIDDLE_MATH',
    HOME_SHOPPING_MIDDLE_ENGLISH = 'HOME_SHOPPING_MIDDLE_ENGLISH',
    HOME_SHOPPING_HIGH_MATH = 'HOME_SHOPPING_HIGH_MATH',
    HOME_SHOPPING_HIGH_ENGLISH = 'HOME_SHOPPING_HIGH_ENGLISH',
    HOME_SHOPPING_MIDDLE_MATH_WEEKEND = 'HOME_SHOPPING_MIDDLE_MATH_WEEKEND',
    HOME_SHOPPING_MIDDLE_ENGLISH_WEEKEND = 'HOME_SHOPPING_MIDDLE_ENGLISH_WEEKEND',
    HOME_SHOPPING_HIGH_MATH_WEEKEND = 'HOME_SHOPPING_HIGH_MATH_WEEKEND',
    HOME_SHOPPING_HIGH_ENGLISH_WEEKEND = 'HOME_SHOPPING_HIGH_ENGLISH_WEEKEND',
    ADDICTIVE_MIDDLE_MATH = 'ADDICTIVE_MIDDLE_MATH',
    ADDICTIVE_MIDDLE_ENGLISH = 'ADDICTIVE_MIDDLE_ENGLISH',
    ADDICTIVE_MIDDLE_MATH_30 = 'ADDICTIVE_MIDDLE_MATH_30',
    ADDICTIVE_MIDDLE_ENGLISH_30 = 'ADDICTIVE_MIDDLE_ENGLISH_30',
    ADDICTIVE_MIDDLE_MATH_SUMMER_VACATION = 'ADDICTIVE_MIDDLE_MATH_SUMMER_VACATION',
    ADDICTIVE_MIDDLE_ENGLISH_SUMMER_VACATION = 'ADDICTIVE_MIDDLE_ENGLISH_SUMMER_VACATION',
    ADDICTIVE_MIDDLE_MATH_4 = 'ADDICTIVE_MIDDLE_MATH_4',
    ADDICTIVE_MIDDLE_ENGLISH_4 = 'ADDICTIVE_MIDDLE_ENGLISH_4',
    ADDICTIVE_MATH_COMPRESSED = 'ADDICTIVE_MATH_COMPRESSED',
    ADDICTIVE_ENGLISH_COMPRESSED = 'ADDICTIVE_ENGLISH_COMPRESSED',
    ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_9_HOUR = 'ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_9_HOUR',
    ADDICTIVE_HIGH_SCHOOL_ENGLISH_9_HOUR = 'ADDICTIVE_HIGH_SCHOOL_ENGLISH_9_HOUR',
    ADDICTIVE_TABLET = 'ADDICTIVE_TABLET',
    ADDICTIVE_TABLET_2 = 'ADDICTIVE_TABLET_2',
    ADDICTIVE_TABLET_3 = 'ADDICTIVE_TABLET_3',
    ADDICTIVE_MONTHLY_BILLING = 'ADDICTIVE_MONTHLY_BILLING',
    ADDICTIVE_COMPRESSED_MONTHLY_BILLING = 'ADDICTIVE_COMPRESSED_MONTHLY_BILLING',
    ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_MONTHLY_BILLING = 'ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_MONTHLY_BILLING',
    ADDICTIVE_MIDDLE_SCHOOL_MATH_MONTHLY_BILLING = 'ADDICTIVE_MIDDLE_SCHOOL_MATH_MONTHLY_BILLING',
    ADDICTIVE_HIGH_SCHOOL_ENGLISH_MONTHLY_BILLING = 'ADDICTIVE_HIGH_SCHOOL_ENGLISH_MONTHLY_BILLING',
    ADDICTIVE_HIGH_SCHOOL_MATH_MONTHLY_BILLING = 'ADDICTIVE_HIGH_SCHOOL_MATH_MONTHLY_BILLING',
    ADDICTIVE_ZOOM_STUDY = 'ADDICTIVE_ZOOM_STUDY',
    TOTAL_SHOP_MIDDLE_MATH = 'TOTAL_SHOP_MIDDLE_MATH',
    TOTAL_SHOP_MIDDLE_ENGLISH = 'TOTAL_SHOP_MIDDLE_ENGLISH',
    TOTAL_SHOP_HIGH_1_MATH = 'TOTAL_SHOP_HIGH_1_MATH',
    TOTAL_SHOP_HIGH_1_ENGLISH = 'TOTAL_SHOP_HIGH_1_ENGLISH',
    TOTAL_SHOP_HIGH_2_MATH = 'TOTAL_SHOP_HIGH_2_MATH',
    TOTAL_SHOP_HIGH_2_ENGLISH = 'TOTAL_SHOP_HIGH_2_ENGLISH',
    TOTAL_SHOP_HIGH_3_MATH = 'TOTAL_SHOP_HIGH_3_MATH',
    TOTAL_SHOP_HIGH_3_ENGLISH = 'TOTAL_SHOP_HIGH_3_ENGLISH',
    TOTAL_SHOP_MIDDLE_MATH_WEEKEND = 'TOTAL_SHOP_MIDDLE_MATH_WEEKEND',
    TOTAL_SHOP_MIDDLE_ENGLISH_WEEKEND = 'TOTAL_SHOP_MIDDLE_ENGLISH_WEEKEND',
    TOTAL_SHOP_HIGH_MATH_WEEKEND = 'TOTAL_SHOP_HIGH_MATH_WEEKEND',
    TOTAL_SHOP_HIGH_ENGLISH_WEEKEND = 'TOTAL_SHOP_HIGH_ENGLISH_WEEKEND',
    TOTAL_SHOP_HIGH_KOREAN_WEEKEND = 'TOTAL_SHOP_HIGH_KOREAN_WEEKEND',
    TOTAL_SHOP_PROMOTION_MIDDLE_MATH = 'TOTAL_SHOP_PROMOTION_MIDDLE_MATH',
    TOTAL_SHOP_PROMOTION_MIDDLE_ENGLISH = 'TOTAL_SHOP_PROMOTION_MIDDLE_ENGLISH',
    TOTAL_SHOP_PROMOTION_HIGH_1_MATH = 'TOTAL_SHOP_PROMOTION_HIGH_1_MATH',
    TOTAL_SHOP_PROMOTION_HIGH_1_ENGLISH = 'TOTAL_SHOP_PROMOTION_HIGH_1_ENGLISH',
    TOTAL_SHOP_PROMOTION_HIGH_2_MATH = 'TOTAL_SHOP_PROMOTION_HIGH_2_MATH',
    TOTAL_SHOP_PROMOTION_HIGH_2_ENGLISH = 'TOTAL_SHOP_PROMOTION_HIGH_2_ENGLISH',
    TOTAL_SHOP_PROMOTION_HIGH_3_MATH = 'TOTAL_SHOP_PROMOTION_HIGH_3_MATH',
    TOTAL_SHOP_PROMOTION_HIGH_3_ENGLISH = 'TOTAL_SHOP_PROMOTION_HIGH_3_ENGLISH',
    TOTAL_SHOP_PROMOTION_MIDDLE_MATH_WEEKEND = 'TOTAL_SHOP_PROMOTION_MIDDLE_MATH_WEEKEND',
    TOTAL_SHOP_PROMOTION_MIDDLE_ENGLISH_WEEKEND = 'TOTAL_SHOP_PROMOTION_MIDDLE_ENGLISH_WEEKEND',
    TOTAL_SHOP_PROMOTION_HIGH_MATH_WEEKEND = 'TOTAL_SHOP_PROMOTION_HIGH_MATH_WEEKEND',
    TOTAL_SHOP_PROMOTION_HIGH_ENGLISH_WEEKEND = 'TOTAL_SHOP_PROMOTION_HIGH_ENGLISH_WEEKEND',
    TOTAL_SHOP_2_MIDDLE_MATH = 'TOTAL_SHOP_2_MIDDLE_MATH',
    TOTAL_SHOP_2_MIDDLE_ENGLISH = 'TOTAL_SHOP_2_MIDDLE_ENGLISH',
    TOTAL_SHOP_2_HIGH_1_MATH = 'TOTAL_SHOP_2_HIGH_1_MATH',
    TOTAL_SHOP_2_HIGH_1_ENGLISH = 'TOTAL_SHOP_2_HIGH_1_ENGLISH',
    TOTAL_SHOP_2_HIGH_2_MATH = 'TOTAL_SHOP_2_HIGH_2_MATH',
    TOTAL_SHOP_2_HIGH_2_ENGLISH = 'TOTAL_SHOP_2_HIGH_2_ENGLISH',
    TOTAL_SHOP_2_HIGH_3_MATH = 'TOTAL_SHOP_2_HIGH_3_MATH',
    TOTAL_SHOP_2_HIGH_3_ENGLISH = 'TOTAL_SHOP_2_HIGH_3_ENGLISH',
    HIGH_MATH_7_HOUR = 'HIGH_MATH_7_HOUR',
    HIGH_MATH_120_MINUTE = 'HIGH_MATH_120_MINUTE',
    HIGH_GRADE_3_MATH_120_MINUTE = 'HIGH_GRADE_3_MATH_120_MINUTE',
}

export interface IPurchaseConfigure {
    [purchaseType: string]: PurchaseConfigureItem;
}

export interface PurchaseConfigureItem {
    color: string;
    paymentType: number;
    isMultiplePayment?: boolean;
    needTablet?: boolean;
    refundDay?: number;
    refundAmount?: number;
    header: PurhcasePageHeader;
    questions: IQuestionAnswerItem[];
    showSections: PurchaseSections;
}

interface HeaderTitleImage {
    type: 'image';
    url: string;
}

interface HeaderTitleText {
    type: 'text';
    title: string;
    description: string;
}

type PurhcasePageHeader = HeaderTitleImage | HeaderTitleText;

interface PurchaseSections {
    section1?: boolean;
    section2?: boolean;
    section3?: boolean;
    section4?: boolean;
    section5?: boolean;
    section6?: boolean;
}

export const PurchaseConfigure: IPurchaseConfigure = {
    [PurchaseType.ELEMENTARY_MATH]: {
        color: '#49D55D',
        paymentType: 127,
        header: { type: 'image', url: '/images/payment-direct/purchase/1-elementary-math.png' },
        questions: questionListDefault,
        showSections: {
            section1: true,
            section2: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.ELEMENTARY_ENGLISH]: {
        color: '#FFE920',
        paymentType: 53,
        header: { type: 'image', url: '/images/payment-direct/purchase/1-elementary-english.png' },
        questions: questionListDefault,
        showSections: {
            section1: true,
            section2: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.ELEMENTARY_KOREAN]: {
        color: '#45D8FA',
        paymentType: 1314,
        header: { type: 'image', url: '/images/payment-direct/purchase/1-elementary-korean.png' },
        questions: questionListDefault,
        showSections: {
            section1: true,
            section2: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.MIDDLE_MATH]: {
        color: '#28D05D',
        paymentType: 32,
        header: { type: 'text', title: '밀당중등수학PT', description: '포기없는 선행으로 고득점 예약' },
        questions: questionListDefault,
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.MIDDLE_ENGLISH]: {
        color: '#FF9800',
        paymentType: 33,
        header: { type: 'text', title: '밀당중등영어PT', description: '1:1 맞춤관리로 공부습관 완성' },
        questions: questionListDefault,
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.HIGH_MATH]: {
        color: '#007DFF',
        paymentType: 34,
        header: { type: 'text', title: '밀당고등수학PT', description: '완벽한 개념과 맞춤 관리로 만드는 1등급' },
        questions: questionListDefault,
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.HIGH_MATH_7_HOUR]: {
        color: '#007DFF',
        paymentType: 1507,
        isMultiplePayment: true,
        header: { type: 'text', title: '밀당고등수학PT', description: '완벽한 개념과 맞춤 관리로 만드는 1등급' },
        questions: questionListDefault,
        showSections: {},
    },
    [PurchaseType.HIGH_MATH_120_MINUTE]: {
        color: '#007DFF',
        paymentType: 1575,
        isMultiplePayment: true,
        header: { type: 'text', title: '밀당고등수학PT', description: '완벽한 개념과 맞춤 관리로 만드는 1등급' },
        questions: questionListDefault,
        showSections: {},
    },
    [PurchaseType.HIGH_GRADE_3_MATH_120_MINUTE]: {
        color: '#007DFF',
        paymentType: 1576,
        isMultiplePayment: true,
        header: { type: 'text', title: '밀당고등수학PT', description: '완벽한 개념과 맞춤 관리로 만드는 1등급' },
        questions: questionListDefault,
        showSections: {},
    },
    [PurchaseType.HIGH_ENGLISH]: {
        color: '#FF505F',
        paymentType: 35,
        header: { type: 'text', title: '밀당고등영어PT', description: '인서울의 성공적인 입시를 위한 필수전략' },
        questions: questionListDefault,
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.ADDICTIVE_MIDDLE_MATH]: {
        color: '#0E2F21',
        paymentType: 80,
        header: { type: 'image', url: '/images/payment-direct/purchase/1-addictive-middle-math.png' },
        questions: questionListDefault,
        showSections: {},
    },
    [PurchaseType.ADDICTIVE_MIDDLE_ENGLISH]: {
        color: '#0E2F21',
        paymentType: 81,
        header: { type: 'image', url: '/images/payment-direct/purchase/1-addictive-middle-english.png' },
        questions: questionListDefault,
        showSections: {},
    },
    [PurchaseType.ADDICTIVE_ZOOM_STUDY]: {
        color: '#0E2F21',
        paymentType: 1508,
        header: { type: 'image', url: '/images/payment-direct/purchase/1-addictive-middle-english.png' },
        questions: questionListDefault,
        showSections: {},
    },
    [PurchaseType.ADDICTIVE_MONTHLY_BILLING]: {
        color: '#0E2F21',
        paymentType: 1051,
        isMultiplePayment: true,
        needTablet: true,
        questions: questionListDefault,
        header: { type: 'image', url: '/images/payment-direct/purchase/1-addictive.png' },
        showSections: {},
    },
    [PurchaseType.ADDICTIVE_TABLET]: {
        color: '#0E2F21',
        paymentType: 1042,
        header: { type: 'image', url: '/images/payment-direct/purchase/1-addictive-tablet.png' },
        questions: questionListDefault,
        showSections: {
            section1: true,
        },
    },
    [PurchaseType.HOME_SHOPPING_MIDDLE_MATH]: {
        color: '#28D05D',
        paymentType: 55,
        refundDay: 14,
        questions: questionListRefundTwoWeek,
        header: { type: 'text', title: '밀당중등수학PT', description: '포기없는 선행으로 고득점 예약' },
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH]: {
        color: '#FF9800',
        paymentType: 56,
        refundDay: 14,
        questions: questionListRefundTwoWeek,
        header: { type: 'text', title: '밀당중등영어PT', description: '1:1 맞춤관리로 공부습관 완성' },
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.HOME_SHOPPING_HIGH_MATH]: {
        color: '#007DFF',
        paymentType: 57,
        refundDay: 14,
        questions: questionListRefundTwoWeek,
        header: { type: 'text', title: '밀당고등수학PT', description: '완벽한 개념과 맞춤 관리로 만드는 1등급' },
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.HOME_SHOPPING_HIGH_ENGLISH]: {
        color: '#FF505F',
        paymentType: 58,
        refundDay: 14,
        questions: questionListRefundTwoWeek,
        header: { type: 'text', title: '밀당고등영어PT', description: '인서울의 성공적인 입시를 위한 필수전략' },
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.HOME_SHOPPING_MIDDLE_MATH_WEEKEND]: {
        color: '#28D05D',
        paymentType: 1004,
        refundDay: 14,
        questions: questionListRefundTwoWeek,
        header: { type: 'text', title: '밀당중등수학PT', description: '포기없는 선행으로 고득점 예약' },
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.HOME_SHOPPING_MIDDLE_ENGLISH_WEEKEND]: {
        color: '#FF9800',
        paymentType: 1005,
        refundDay: 14,
        questions: questionListRefundTwoWeek,
        header: { type: 'text', title: '밀당중등영어PT', description: '1:1 맞춤관리로 공부습관 완성' },
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.HOME_SHOPPING_HIGH_MATH_WEEKEND]: {
        color: '#007DFF',
        paymentType: 1006,
        refundDay: 14,
        questions: questionListRefundTwoWeek,
        header: { type: 'text', title: '밀당고등수학PT', description: '완벽한 개념과 맞춤 관리로 만드는 1등급' },
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.HOME_SHOPPING_HIGH_ENGLISH_WEEKEND]: {
        color: '#FF505F',
        paymentType: 1007,
        refundDay: 14,
        questions: questionListRefundTwoWeek,
        header: { type: 'text', title: '밀당고등영어PT', description: '인서울의 성공적인 입시를 위한 필수전략' },
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.TOTAL_SHOP_MIDDLE_MATH]: {
        color: '#48B6FF',
        paymentType: 1086,
        header: { type: 'text', title: '밀당중등수학PT', description: '포기없는 선행으로 고득점 예약' },
        questions: questionListMiddleSchool,
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.TOTAL_SHOP_MIDDLE_ENGLISH]: {
        color: '#FF779F',
        paymentType: 1083,
        header: { type: 'text', title: '밀당중등영어PT', description: '1:1 맞춤관리로 공부습관 완성' },
        questions: questionListMiddleSchool,
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_1_MATH]: {
        color: '#48B6FF',
        paymentType: 1092,
        header: { type: 'text', title: '밀당고등수학PT', description: '완벽한 개념과 맞춤 관리로 만드는 1등급' },
        questions: questionListHighSchool1,
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_1_ENGLISH]: {
        color: '#FF779F',
        paymentType: 1090,
        header: { type: 'text', title: '밀당고등영어PT', description: '인서울의 성공적인 입시를 위한 필수전략' },
        questions: questionListHighSchool1,
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_2_MATH]: {
        color: '#48B6FF',
        paymentType: 1087,
        header: { type: 'text', title: '밀당고등수학PT', description: '완벽한 개념과 맞춤 관리로 만드는 1등급' },
        questions: questionListHighSchool2Math,
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_2_ENGLISH]: {
        color: '#FF779F',
        paymentType: 1084,
        header: { type: 'text', title: '밀당고등영어PT', description: '인서울의 성공적인 입시를 위한 필수전략' },
        questions: questionListHighSchool2,
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_3_MATH]: {
        color: '#48B6FF',
        paymentType: 1088,
        header: { type: 'text', title: '밀당고등수학PT', description: '완벽한 개념과 맞춤 관리로 만드는 1등급' },
        questions: questionListHighSchool3Math,
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_3_ENGLISH]: {
        color: '#FF779F',
        paymentType: 1085,
        header: { type: 'text', title: '밀당고등영어PT', description: '인서울의 성공적인 입시를 위한 필수전략' },
        questions: questionListHighSchool3,
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.TOTAL_SHOP_MIDDLE_MATH_WEEKEND]: {
        color: '#48B6FF',
        paymentType: 1095,
        header: { type: 'text', title: '밀당중등수학PT', description: '포기없는 선행으로 고득점 예약' },
        questions: questionListDefault,
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.TOTAL_SHOP_MIDDLE_ENGLISH_WEEKEND]: {
        color: '#FF779F',
        paymentType: 1093,
        header: { type: 'text', title: '밀당중등영어PT', description: '1:1 맞춤관리로 공부습관 완성' },
        questions: questionListDefault,
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_MATH_WEEKEND]: {
        color: '#48B6FF',
        paymentType: 1096,
        header: { type: 'text', title: '밀당고등수학PT', description: '완벽한 개념과 맞춤 관리로 만드는 1등급' },
        questions: questionListDefault,
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_ENGLISH_WEEKEND]: {
        color: '#FF779F',
        paymentType: 1094,
        header: { type: 'text', title: '밀당고등영어PT', description: '인서울의 성공적인 입시를 위한 필수전략' },
        questions: questionListDefault,
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
    [PurchaseType.TOTAL_SHOP_HIGH_KOREAN_WEEKEND]: {
        color: '#F3BB40',
        paymentType: 1436,
        header: { type: 'text', title: '밀당고등국어PT', description: '수능 국어 완전 점령' },
        questions: questionListDefault,
        showSections: {
            section1: true,
            section2: true,
            section3: true,
            section4: true,
            section5: true,
            section6: true,
        },
    },
};

// ELEMENTARY
PurchaseConfigure[PurchaseType.ELEMENTARY_NEW_MATH] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_MATH], (draft) => {
    draft.paymentType = 1082;
});
PurchaseConfigure[PurchaseType.ELEMENTARY_NEW_ENGLISH] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_ENGLISH], (draft) => {
    draft.paymentType = 1081;
});
PurchaseConfigure[PurchaseType.ELEMENTARY_NEW_KOREAN] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_KOREAN], (draft) => {
    draft.paymentType = 1314;
});
PurchaseConfigure[PurchaseType.ELEMENTARY_NEW_MATH_WEEKEND] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_MATH], (draft) => {
    draft.paymentType = 1098;
});
PurchaseConfigure[PurchaseType.ELEMENTARY_NEW_ENGLISH_WEEKEND] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_ENGLISH], (draft) => {
    draft.paymentType = 1097;
});

// ELEMENTARY_PORMOTION
PurchaseConfigure[PurchaseType.ELEMENTARY_PROMOTION_ENGLISH] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_ENGLISH], (draft) => {
    draft.paymentType = 1373;
});
PurchaseConfigure[PurchaseType.ELEMENTARY_PROMOTION_MATH] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_MATH], (draft) => {
    draft.paymentType = 1374;
});
PurchaseConfigure[PurchaseType.ELEMENTARY_PROMOTION_KOREAN] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_KOREAN], (draft) => {
    draft.paymentType = 1375;
});
PurchaseConfigure[PurchaseType.ELEMENTARY_PROMOTION_MATH_WEEKEND] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_MATH], (draft) => {
    draft.paymentType = 1377;
});
PurchaseConfigure[PurchaseType.ELEMENTARY_PROMOTION_ENGLISH_WEEKEND] = produce(PurchaseConfigure[PurchaseType.ELEMENTARY_ENGLISH], (draft) => {
    draft.paymentType = 1376;
});

// TOTAL_SHOP
PurchaseConfigure[PurchaseType.TOTAL_SHOP_PROMOTION_MIDDLE_MATH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_MIDDLE_MATH], (draft) => {
    draft.paymentType = 1361;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_PROMOTION_MIDDLE_ENGLISH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_MIDDLE_ENGLISH], (draft) => {
    draft.paymentType = 1362;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_PROMOTION_HIGH_1_MATH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_1_MATH], (draft) => {
    draft.paymentType = 1363;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_PROMOTION_HIGH_1_ENGLISH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_1_ENGLISH], (draft) => {
    draft.paymentType = 1364;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_PROMOTION_HIGH_2_MATH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_2_MATH], (draft) => {
    draft.paymentType = 1365;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_PROMOTION_HIGH_2_ENGLISH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_2_ENGLISH], (draft) => {
    draft.paymentType = 1366;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_PROMOTION_HIGH_3_MATH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_3_MATH], (draft) => {
    draft.paymentType = 1367;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_PROMOTION_HIGH_3_ENGLISH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_3_ENGLISH], (draft) => {
    draft.paymentType = 1368;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_PROMOTION_MIDDLE_MATH_WEEKEND] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_MIDDLE_MATH_WEEKEND], (draft) => {
    draft.paymentType = 1369;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_PROMOTION_MIDDLE_ENGLISH_WEEKEND] = produce(
    PurchaseConfigure[PurchaseType.TOTAL_SHOP_MIDDLE_ENGLISH_WEEKEND],
    (draft) => {
        draft.paymentType = 1370;
    },
);
PurchaseConfigure[PurchaseType.TOTAL_SHOP_PROMOTION_HIGH_MATH_WEEKEND] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_MATH_WEEKEND], (draft) => {
    draft.paymentType = 1371;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_PROMOTION_HIGH_ENGLISH_WEEKEND] = produce(
    PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_ENGLISH_WEEKEND],
    (draft) => {
        draft.paymentType = 1372;
    },
);

PurchaseConfigure[PurchaseType.TOTAL_SHOP_2_MIDDLE_MATH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_MIDDLE_MATH], (draft) => {
    draft.paymentType = 1509;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_2_MIDDLE_ENGLISH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_MIDDLE_ENGLISH], (draft) => {
    draft.paymentType = 1510;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_2_HIGH_1_MATH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_1_MATH], (draft) => {
    draft.paymentType = 1511;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_2_HIGH_1_ENGLISH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_1_ENGLISH], (draft) => {
    draft.paymentType = 1512;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_2_HIGH_2_MATH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_2_MATH], (draft) => {
    draft.paymentType = 1513;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_2_HIGH_2_ENGLISH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_2_ENGLISH], (draft) => {
    draft.paymentType = 1514;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_2_HIGH_3_MATH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_3_MATH], (draft) => {
    draft.paymentType = 1515;
});
PurchaseConfigure[PurchaseType.TOTAL_SHOP_2_HIGH_3_ENGLISH] = produce(PurchaseConfigure[PurchaseType.TOTAL_SHOP_HIGH_3_ENGLISH], (draft) => {
    draft.paymentType = 1516;
});

// ADDICTIVE
PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_MATH_30] = produce(PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_MATH], (draft) => {
    draft.paymentType = 1000;
});
PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_ENGLISH_30] = produce(PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_ENGLISH], (draft) => {
    draft.paymentType = 1001;
});
PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_MATH_SUMMER_VACATION] = produce(PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_MATH], (draft) => {
    draft.paymentType = 1002;
});
PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_ENGLISH_SUMMER_VACATION] = produce(PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_ENGLISH], (draft) => {
    draft.paymentType = 1003;
});
PurchaseConfigure[PurchaseType.ADDICTIVE_MATH_COMPRESSED] = produce(PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_MATH], (draft) => {
    draft.paymentType = 1066;
});
PurchaseConfigure[PurchaseType.ADDICTIVE_ENGLISH_COMPRESSED] = produce(PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_ENGLISH], (draft) => {
    draft.paymentType = 1067;
});
PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_MATH_4] = produce(PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_MATH], (draft) => {
    draft.paymentType = 1040;
    draft.showSections.section1 = true;
});
PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_ENGLISH_4] = produce(PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_ENGLISH], (draft) => {
    draft.paymentType = 1041;
    draft.showSections.section1 = true;
});
PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_9_HOUR] = produce(PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_ENGLISH], (draft) => {
    draft.paymentType = 1347;
});
PurchaseConfigure[PurchaseType.ADDICTIVE_HIGH_SCHOOL_ENGLISH_9_HOUR] = produce(PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_ENGLISH], (draft) => {
    draft.paymentType = 1348;
});

PurchaseConfigure[PurchaseType.ADDICTIVE_COMPRESSED_MONTHLY_BILLING] = produce(PurchaseConfigure[PurchaseType.ADDICTIVE_MONTHLY_BILLING], (draft) => {
    draft.paymentType = 1068;
});
PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_SCHOOL_MATH_MONTHLY_BILLING] = produce(PurchaseConfigure[PurchaseType.ADDICTIVE_MONTHLY_BILLING], (draft) => {
    draft.paymentType = 1339;
});
PurchaseConfigure[PurchaseType.ADDICTIVE_MIDDLE_SCHOOL_ENGLISH_MONTHLY_BILLING] = produce(
    PurchaseConfigure[PurchaseType.ADDICTIVE_MONTHLY_BILLING],
    (draft) => {
        draft.paymentType = 1338;
    },
);
PurchaseConfigure[PurchaseType.ADDICTIVE_HIGH_SCHOOL_MATH_MONTHLY_BILLING] = produce(PurchaseConfigure[PurchaseType.ADDICTIVE_MONTHLY_BILLING], (draft) => {
    draft.paymentType = 1343;
});
PurchaseConfigure[PurchaseType.ADDICTIVE_HIGH_SCHOOL_ENGLISH_MONTHLY_BILLING] = produce(PurchaseConfigure[PurchaseType.ADDICTIVE_MONTHLY_BILLING], (draft) => {
    draft.paymentType = 1342;
});
PurchaseConfigure[PurchaseType.ADDICTIVE_TABLET_2] = produce(PurchaseConfigure[PurchaseType.ADDICTIVE_TABLET], (draft) => {
    draft.paymentType = 1052;
});
PurchaseConfigure[PurchaseType.ADDICTIVE_TABLET_3] = produce(PurchaseConfigure[PurchaseType.ADDICTIVE_TABLET], (draft) => {
    draft.paymentType = 1053;
});
