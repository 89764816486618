import { PaymentModuleType, PayMethod } from '../constants/payment';
import { PurchaseType } from '../constants/purchase-page';
import { flatMap, head, isNil, pipe, toArray } from '@fxts/core';
import { nanoid } from 'nanoid';
import { ProductBillingType } from '../constants/constants';
import { getIamportDisplay } from './card-quota';
import dateformat from 'dateformat';
import { DateTime } from 'luxon';
import { getIsBillingDayPassed, getLastClassDate } from './time';
import { ProductCompositionType } from '../constants/payment-page';
import { ProductSet } from '../constants/product-set';

export type MerchantType = 'ihateflyingbugs' | 'maesian';

export interface IPurchaseWithIamPort {
    products: any[];
    payMethod: PayMethod;
    amount: number;
    buyerName: string;
    buyerTel: string;
    queryParams?: any[];
    token?: string;
    direct?: boolean;
    extension?: boolean;
    // DIRECT
    purchaseType?: PurchaseType;
    // MONTHLY_BILLING
    startDate?: Date;
    monthlyBillingDay?: number;
    // 권한 시작일, 종료일이 지정된 경우 연장 결제 여부와 상관없이 이 값을 사용
    // yyyy-mm-dd
    startDateOfClass?: string;
    endDateOfClass?: string;
    merchantType?: MerchantType;
}

const getPgProvider = (merchantType: MerchantType, billingType: PaymentModuleType, payMethod: PayMethod) => {
    if (merchantType === 'ihateflyingbugs') {
        if (billingType === PaymentModuleType.BILLING) return `tosspayments`;
        if (payMethod === 'kakaopay') return 'kakaopay';
        return 'uplus';
    }
    if (merchantType === 'maesian') return 'tosspayments';
    return '';
};

export const purchaseWithIamport = (input: IPurchaseWithIamPort): void => {
    const {
        products,
        payMethod: _payMethod,
        amount: amountOrigin,
        buyerName,
        buyerTel,
        token,
        queryParams,
        purchaseType,
        direct: isDirect = false,
        extension: isExtended = false,
        monthlyBillingDay: monthlyBillingDayOrigin,
        startDate,
        startDateOfClass: startDateOfClassOrigin,
        endDateOfClass: endDateOfClassOrigin,
        merchantType = 'ihateflyingbugs',
    } = input;

    const paymentModuleType = getPaymentModuleType(products);
    const billingType = getBillingType(products);

    const buyerEmail = '';
    const name = products.map((product) => product.name).join(', ');

    const productIds = products.map((product) => product.id);
    const productId = paymentModuleType === PaymentModuleType.NORMAL ? head(productIds) : undefined;
    const pgProvider = getPgProvider(merchantType, paymentModuleType, _payMethod);

    const payMethod = paymentModuleType === PaymentModuleType.BILLING ? 'card' : _payMethod;
    const merchantUid = paymentModuleType === PaymentModuleType.BILLING ? 'issue_billingkey_' + nanoid() : 'merchant_' + nanoid();
    const amount = paymentModuleType === PaymentModuleType.BILLING ? 0 : amountOrigin;

    const customerUid = paymentModuleType === PaymentModuleType.BILLING ? 'customer_' + nanoid() : undefined;
    const monthlyBillingDay = paymentModuleType === PaymentModuleType.BILLING ? monthlyBillingDayOrigin : undefined;
    const startDateOfClass = getStartDateOfClass(paymentModuleType, startDateOfClassOrigin, startDate);
    const endDateOfClass = getEndDateOfClass(paymentModuleType, billingType, endDateOfClassOrigin, startDate, monthlyBillingDayOrigin);

    const customData = {
        isDirect,
        isExtended,
        monthlyBillingDay,
        startDateOfClass,
        endDateOfClass,
        productIds,
        productId,
        token,
        queryParams,
    };
    console.log(customData);

    requestPay(
        {
            pg: pgProvider,
            pay_method: payMethod,
            merchant_uid: merchantUid,
            customer_uid: customerUid,
            amount: amount,
            tax_free: amount,
            name: name,
            buyer_email: buyerEmail,
            buyer_name: buyerName,
            buyer_tel: buyerTel,
            custom_data: customData,
            vbank_due: dateformat(Date.now() + 1000 * 60 * 60 * 2, 'yyyymmddHHMM'),
            ...getIamportDisplay(amount),
        },
        purchaseType,
        merchantType,
        startDateOfClass,
    );
};

export interface IPurchaseProductSetWithIamport {
    productSet: ProductSet;
    payMethod: PayMethod;
    buyerName: string;
    buyerTel: string;
    queryParams?: any[];
    token?: string;
    direct?: boolean;
    extension?: boolean;
    purchaseType?: PurchaseType;
    merchantType?: MerchantType;
    // MONTHLY_BILLING
    startDate?: Date;
    monthlyBillingDay?: number;
    // 권한 시작일, 종료일이 지정된 경우 연장 결제 여부와 상관없이 이 값을 사용
    // yyyy-mm-dd
    startDateOfClass?: string;
    endDateOfClass?: string;
}

export const purchaseProductSetWithIamport = (input: IPurchaseProductSetWithIamport): void => {
    const {
        productSet,
        payMethod: _payMethod,
        buyerName,
        buyerTel,
        token,
        queryParams,
        purchaseType,
        direct: isDirect = false,
        extension: isExtended = false,
        monthlyBillingDay: monthlyBillingDayOrigin,
        startDate,
        startDateOfClass: startDateOfClassOrigin,
        endDateOfClass: endDateOfClassOrigin,
        merchantType = 'ihateflyingbugs',
    } = input;

    const paymentModuleType = PaymentModuleType.BILLING;
    const billingType = getBillingType(productSet.products);

    const buyerEmail = '';
    const name = productSet.name;

    const productIds: number[] = pipe(
        productSet.products as any[],
        flatMap((product) => Array(Number(product.quantity)).fill(product.id)),
        toArray,
    );

    const pgProvider = getPgProvider(merchantType, paymentModuleType, _payMethod);

    const payMethod = 'card';
    const merchantUid = 'issue_billingkey_' + nanoid();
    const amount = 0;

    const customerUid = 'customer_' + nanoid();
    const productCompositionType = ProductCompositionType.PRODUCT_SET;
    const productSetId = productSet.id;

    const monthlyBillingDay = paymentModuleType === PaymentModuleType.BILLING ? monthlyBillingDayOrigin : undefined;
    const startDateOfClass = getStartDateOfClass(paymentModuleType, startDateOfClassOrigin, startDate);
    const endDateOfClass = getEndDateOfClass(paymentModuleType, billingType, endDateOfClassOrigin, startDate, monthlyBillingDayOrigin);

    const customData = {
        isDirect,
        isExtended,
        monthlyBillingDay,
        startDateOfClass,
        endDateOfClass,
        productIds,
        productCompositionType,
        productSetId,
        token,
        queryParams,
    };

    requestPay(
        {
            pg: pgProvider,
            pay_method: payMethod,
            merchant_uid: merchantUid,
            customer_uid: customerUid,
            amount: amount,
            tax_free: amount,
            name: name,
            buyer_email: buyerEmail,
            buyer_name: buyerName,
            buyer_tel: buyerTel,
            custom_data: customData,
            vbank_due: dateformat(Date.now() + 1000 * 60 * 60 * 2, 'yyyymmddHHMM'),
            ...getIamportDisplay(amount),
        },
        purchaseType,
        merchantType,
    );
};

const getMerchantCode = (merchatType?: MerchantType) => {
    if (merchatType === 'ihateflyingbugs') return 'imp02579783';
    if (merchatType === 'maesian') return 'imp08020732';
    return 'imp02579783';
};

const requestPay = (body: any, purchaseType?: PurchaseType, merchatType?: MerchantType, startDateOfClass?: string) => {
    const pathname = window.location.pathname;
    const merchantCode = getMerchantCode(merchatType);
    const redirectUrl = new URL(window.origin + `/result?purchase_type=${purchaseType}&path=${pathname}`);

    redirectUrl.searchParams.set('path', pathname);
    if (!isNil(purchaseType)) redirectUrl.searchParams.set('purchase_type', purchaseType);
    if (!isNil(startDateOfClass)) redirectUrl.searchParams.set('start_date_of_class', startDateOfClass);

    (window as any).IMP.init(merchantCode);
    (window as any).IMP.request_pay(
        {
            ...body,
            m_redirect_url: redirectUrl.toString(),
        },
        (rsp) => {
            const impUid = rsp.imp_uid;
            const merchantUid = rsp.merchant_uid;
            const impSuccess = rsp.success;
            redirectUrl.searchParams.set('imp_uid', impUid);
            redirectUrl.searchParams.set('merchant_uid', merchantUid);
            redirectUrl.searchParams.set('imp_success', impSuccess);

            if (isNil(rsp.error_msg)) {
                window.location.href = redirectUrl.toString();
            } else {
                alert(`결제에 실패하였습니다.\n에러내용: ${rsp.error_msg}`);
            }
        },
    );
};

const getPaymentModuleType = (products: any[]): PaymentModuleType => {
    if (products.length > 1) return PaymentModuleType.BILLING;
    if (products.some((item) => item.billingType === ProductBillingType.RECURRING)) {
        return PaymentModuleType.BILLING;
    }
    if (products.some((item) => item.billingType === ProductBillingType.MONTHLY_BILLING)) {
        return PaymentModuleType.BILLING;
    }
    return PaymentModuleType.NORMAL;
};

/*
 *  같은 유형의 상품만 한번에 결제할 수 있음.
 * */
const getBillingType = (products: any[]): ProductBillingType => {
    if (products.every((item) => item.billingType === ProductBillingType.RECURRING)) {
        return ProductBillingType.RECURRING;
    }
    if (products.every((item) => item.billingType === ProductBillingType.MONTHLY_BILLING)) {
        return ProductBillingType.MONTHLY_BILLING;
    }
    if (products.every((item) => item.billingType === ProductBillingType.NORMAL)) {
        return ProductBillingType.NORMAL;
    }
    throw new Error('여러 결제 유형의 상품을 한번에 결제할 수 없습니다.');
};

const getStartDateOfClass = (paymentModuleType: PaymentModuleType, startDateOfClass?: string, startDate?: Date) => {
    if (!isNil(startDateOfClass)) return startDateOfClass;
    if (paymentModuleType !== PaymentModuleType.BILLING) return undefined;
    if (isNil(startDate)) return undefined;
    return DateTime.fromJSDate(new Date(startDate)).toFormat('yyyy-MM-dd');
};

const getEndDateOfClass = (
    paymentModuleType: PaymentModuleType,
    billingType: ProductBillingType,
    endDateOfClass?: string,
    startDate?: Date,
    monthlyBillingDay?: number,
) => {
    if (!isNil(endDateOfClass)) return endDateOfClass;
    if (paymentModuleType !== PaymentModuleType.BILLING) return undefined;
    if (billingType === ProductBillingType.MONTHLY_BILLING) {
        if (isNil(startDate) || isNil(monthlyBillingDay)) return undefined;
        return getLastClassDate(new Date(startDate), getIsBillingDayPassed(new Date(startDate), monthlyBillingDay)).toFormat('yyyy-MM-dd');
    }
    return undefined;
};
