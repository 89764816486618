import React, { ReactNode } from 'react';
import { changeCurrencyUnit } from '../../../utils/currency';
import { CircularProgress, Divider } from '@material-ui/core';
import { PaymentResultStatus } from '../../../constants/constants';
import dateformat from 'dateformat';
import styled from 'styled-components';
import { ButtonToAddCustomerCenter, ButtonToReturnPayment } from './PaymentDirectResultButton';
import { PaymentData } from '../../../utils/payment';
import { isNil } from '@fxts/core';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: Pretendard, sans-serif;
    font-style: normal;
`;

const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
    width: 100%;
    max-width: 600px;
    justify-content: center;
    margin: 0px auto;
    padding: 0.5rem;
`;

const ResultWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
    background: #ffffff;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
`;

const ResultHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    gap: 0.5rem;
`;

const ResultBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1rem;
`;

const ResultDetailRowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    font-weight: ${(props) => (props.bold ? '700' : 'inherit')};
    color: ${(props) => (props.color ? props.color : 'inherit')};
`;

const ResultDetailRowHeader = styled.span`
    width: 8rem;
`;

const NoticeRowWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
`;

const NoticeDetail = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;

const NoticeAdditional = styled.span`
    font-size: 0.875rem;
    color: #8c8c8c;
`;

interface PaymentDirectResultViewerProps {
    payments: PaymentData[];
    billingKeyPayment: PaymentData | null;
    status: string;
    purchaseType: string;
    errorMsg: string;
    isProductSet: boolean;
}

export const PaymentDirectResultViewer = (props: PaymentDirectResultViewerProps) => {
    document.body.style.backgroundColor = '#f2f2f2';

    const { payments, billingKeyPayment, status, purchaseType, errorMsg, isProductSet } = props;

    const paymentCard = () => {
        if (isProductSet && !isNil(billingKeyPayment)) {
            if (payments.every((payment) => payment.status === 'paid')) {
                return <PaymentSetSucceed billingKeyPayment={billingKeyPayment} payments={payments} />;
            }
            if (payments.every((payment) => payment.status === 'cancelled')) {
                return <PaymentSetCancelled billingKeyPayment={billingKeyPayment} payments={payments} />;
            }
            if (payments.some((payment) => payment.status === 'failed')) {
                const failedPayment = payments.find((payment) => payment.status === 'failed');
                if (isNil(failedPayment)) return <PaymentInvalid errorMsg={errorMsg} />;
                return <PaymentFailed payment={failedPayment} />;
            }
        }

        return (
            <>
                {payments.map((payment, key) => {
                    if (payment.status === 'paid' || payment.status === 'ready') {
                        return <PaymentSucceed payment={payment} key={key} />;
                    }
                    if (payment.status === 'cancelled') {
                        return <PaymentCancelled payment={payment} key={key} />;
                    }
                    if (payment.status === 'failed') {
                        return <PaymentFailed payment={payment} key={key} />;
                    }
                    return <PaymentInvalid key={key} errorMsg={errorMsg} />;
                })}
            </>
        );
    };

    if (status === PaymentResultStatus.SUCCEED) {
        return (
            <PaymentResultContainer>
                {paymentCard()}
                {payments.some((payment) => payment.isDirect === 1) && <NoticeToPaidUser />}
                <ButtonToReturnPayment purchaseType={purchaseType} />
                <ButtonToAddCustomerCenter />
            </PaymentResultContainer>
        );
    }
    if (status === PaymentResultStatus.PENDING)
        return (
            <PaymentResultContainer>
                <PaymentPending />
            </PaymentResultContainer>
        );
    return <PaymentInvalid errorMsg={errorMsg} />;
};

const PaymentSucceed = (props: { payment: PaymentData }) => {
    const { payment } = props;
    const isReady = payment.status === 'ready';
    return (
        <ResultWrapper>
            <ResultHeader>
                <img src={'/images/payment-direct/result/succeed.png'} alt={'succeed'} width={80} height={80} />
                <span>{isReady ? '가상계좌 결제 대기중입니다' : '결제가 완료되었습니다'}</span>
            </ResultHeader>
            <ResultBody>
                <ResultDetailRow label={'주문번호'} value={payment.impUid} />
                <ResultDetailRow label={'구매자'} value={payment.buyerName} />
                <ResultDetailRow label={'연락처'} value={payment.buyerTel} />
                <ResultDetailRow label={'구매상품'} value={payment.name} />
                <Divider />
                {isReady && (
                    <>
                        <ResultDetailRow label={'예금주'} value={payment?.vbankHolder ?? ''} />
                        <ResultDetailRow label={'은행명'} value={payment?.vbankName ?? ''} />
                        <ResultDetailRow label={'입금계좌'} value={payment?.vbankNum ?? ''} />
                        <ResultDetailRow label={'입금기한'} value={dateformat(payment.vbankDate, 'yyyy-mm-dd HH:MM:ss')} />
                    </>
                )}
                <ResultDetailRow color={'#25866F'} bold={true} label={'결제금액'} value={`${changeCurrencyUnit(payment.amount)}원`} />
                {!isReady && <ResultDetailRow label={'결제일시'} value={dateformat(payment.paidAt, 'yyyy-mm-dd HH:MM:ss')} />}
            </ResultBody>
        </ResultWrapper>
    );
};

const PaymentSetSucceed = (props: { billingKeyPayment: PaymentData; payments: PaymentData[] }) => {
    const { billingKeyPayment, payments } = props;
    const amount = payments.reduce((acc, cur) => {
        return acc + cur.amount;
    }, 0);

    return (
        <ResultWrapper>
            <ResultHeader>
                <img src={'/images/payment-direct/result/succeed.png'} alt={'succeed'} width={80} height={80} />
            </ResultHeader>
            <ResultBody>
                <ResultDetailRow label={'주문번호'} value={billingKeyPayment.impUid} />
                <ResultDetailRow label={'구매자'} value={billingKeyPayment.buyerName} />
                <ResultDetailRow label={'연락처'} value={billingKeyPayment.buyerTel} />
                <ResultDetailRow label={'구매상품'} value={billingKeyPayment.name} />
                <Divider />
                <ResultDetailRow color={'#25866F'} bold={true} label={'결제금액'} value={`${changeCurrencyUnit(amount)}원`} />
            </ResultBody>
        </ResultWrapper>
    );
};

const PaymentCancelled = (props: { payment: PaymentData }) => {
    const { payment } = props;

    return (
        <ResultWrapper>
            <ResultHeader>
                <img src={'/images/payment-direct/result/fail.png'} alt={'fail'} width={80} height={80} />
                <span>결제가 취소되었습니다</span>
            </ResultHeader>
            <ResultBody>
                <ResultDetailRow label={'주문번호'} value={payment.impUid} />
                <ResultDetailRow label={'구매자'} value={payment.buyerName} />
                <ResultDetailRow label={'연락처'} value={payment.buyerTel} />
                <ResultDetailRow label={'구매상품'} value={payment.name} />
                <Divider />
                <ResultDetailRow label={'취소일시'} value={dateformat(payment.cancelledAt, 'yyyy-mm-dd HH:MM:ss')} />
                <ResultDetailRow label={'취소금액'} value={`${changeCurrencyUnit(payment.cancelAmount ?? 0)}원`} color={'#25866F'} bold={true} />
            </ResultBody>
        </ResultWrapper>
    );
};

const PaymentSetCancelled = (props: { billingKeyPayment: PaymentData; payments: PaymentData[] }) => {
    const { billingKeyPayment, payments } = props;
    const cancelAmount = payments.reduce((acc, cur) => {
        return acc + (cur.cancelAmount ?? 0);
    }, 0);

    return (
        <ResultWrapper>
            <ResultHeader>
                <img src={'/images/payment-direct/result/fail.png'} alt={'fail'} width={80} height={80} />
                <span>결제가 취소되었습니다</span>
            </ResultHeader>
            <ResultBody>
                <ResultDetailRow label={'주문번호'} value={billingKeyPayment.impUid} />
                <ResultDetailRow label={'구매자'} value={billingKeyPayment.buyerName} />
                <ResultDetailRow label={'연락처'} value={billingKeyPayment.buyerTel} />
                <ResultDetailRow label={'구매상품'} value={billingKeyPayment.name} />
                <Divider />
                <ResultDetailRow label={'취소금액'} value={`${changeCurrencyUnit(cancelAmount)}원`} color={'#25866F'} bold={true} />
            </ResultBody>
        </ResultWrapper>
    );
};

const PaymentFailed = (props: { payment: PaymentData }) => {
    const { payment } = props;

    return (
        <ResultWrapper>
            <ResultHeader>
                <img src={'/images/payment-direct/result/fail.png'} alt={'fail'} width={80} height={80} />
                <span>결제가 실패했습니다</span>
            </ResultHeader>
            <ResultBody>
                <ResultDetailRow label={'실패사유'} value={payment.failReason ?? ''} />
            </ResultBody>
        </ResultWrapper>
    );
};

const PaymentPending = () => {
    return (
        <ResultWrapper>
            <ResultHeader>
                <CircularProgress size={80} />
                <div>
                    <div>{'결제 진행 중입니다'}</div>
                    <div>{'잠시만 기다려주세요'}</div>
                </div>
            </ResultHeader>
        </ResultWrapper>
    );
};

const PaymentInvalid = (props: { errorMsg: string; purchaseType?: string }) => {
    const { errorMsg, purchaseType } = props;
    return (
        <PaymentResultContainer>
            <ResultWrapper>
                <ResultHeader>
                    <img src={'/images/payment-direct/result/error.png'} alt={'error'} width={80} height={80} />
                    <span>결제 오류입니다</span>
                </ResultHeader>
                {errorMsg && <div>{errorMsg}</div>}
            </ResultWrapper>
            <ButtonToReturnPayment purchaseType={purchaseType} />
        </PaymentResultContainer>
    );
};

const NoticeRow = (props: { header: string; children: ReactNode }) => {
    const { header, children } = props;
    return (
        <NoticeRowWrapper>
            <span>{header}</span>
            <NoticeDetail>{children}</NoticeDetail>
        </NoticeRowWrapper>
    );
};

const NoticeToPaidUser = () => {
    return (
        <ResultWrapper>
            <NoticeRow header={'①'}>
                <span>
                    결제한 연락처로 발송되는 <b>원생등록지를 꼭 작성해주세요.</b>
                </span>
                <NoticeAdditional>(작성 및 전송이 완료되어야만 개강을 시작할 수 있습니다)</NoticeAdditional>
            </NoticeRow>
            <NoticeRow header={'②'}>
                <span>진단고사 일정 및 개강일자, 선생님 배정이 완료된 경우, 상담사를 통해 유선으로 자세하게 안내드리겠습니다.</span>
                <NoticeAdditional>(결제량이 많아 등록일 기준으로 순차적으로 연락드리고 있는 중이기 때문에 양해부탁드리겠습니다.)</NoticeAdditional>
            </NoticeRow>

            <NoticeRow header={'③'}>
                <span>밀당PT 고객센터 채널을 친구 추가한 뒤에 원생등록지 미확인 및 결제 이후 발생된 문의가 있으실 경우 알려주세요.</span>
            </NoticeRow>
        </ResultWrapper>
    );
};

const PaymentResultContainer = (props: { children: ReactNode }) => {
    const { children } = props;
    return (
        <Wrapper>
            <InnerWrapper>{children}</InnerWrapper>
        </Wrapper>
    );
};

const ResultDetailRow = (props: { label: string; value: string; bold?: boolean; color?: string }) => {
    const { label, value, bold, color } = props;
    return (
        <ResultDetailRowWrapper bold={bold} color={color}>
            <ResultDetailRowHeader>{label}</ResultDetailRowHeader>
            <span>{value}</span>
        </ResultDetailRowWrapper>
    );
};
