import React, { useEffect, useState } from 'react';
import { Footer } from '../../components/common/Footer';
import { purchaseProductSetWithIamport } from '../../utils/purchase';
import { GRAPHQL_URL, MAX_DESKTOP_WIDTH, REST_URL } from '../../constants/constants';
import { regExp } from '../../utils/reg-exp';
import { googleAnalyticsConfig, googleAnalyticsEvent } from '../../utils/google-analytics';
import { ajax } from '../../utils/jQuery';
import { PayMethod } from '../../constants/payment';
import { ProductRulesAccordion } from '../../components/purchase/ProductRulesAccordion';
import { ProductSetList } from '../../components/payment/ProductSetList';
import { PaymentPageOptions } from '../../constants/payment-page';
import { ProductSet } from '../../constants/product-set';
import styled from 'styled-components';
import { PaymentSetDialog } from '../../components/payment/PaymentSetDialog';
import { isNil } from '@fxts/core';

const Wrapper = styled.div`
    display: flex;
`;

const SectionWrapper = styled.div`
    flex: ${MAX_DESKTOP_WIDTH}px 0 1;
    margin: 0 auto;
}`;

const SectionImage = styled.img`
    display: block;
    width: 100%;
`;

interface PaymentSetState {
    selectedProductSet?: any;
    productSets: ProductSet[];
    isDialogOpen: boolean;
    name: string;
    phoneNumber: string;
    payMethod: PayMethod;
    contentWidth: number;
    authCode: string;
    isSentVerifiedCode: boolean;
}

interface PaymentSetProps {
    isMobile: boolean;
    paymentType: number;
    options: PaymentPageOptions;
    token: string;
    direct?: boolean;
    extension?: boolean;
    recurring?: boolean;
    queryParams?: any;
    firstDate: Date | null;
}

function PaymentSet(props: PaymentSetProps) {
    const initialStates: PaymentSetState = {
        selectedProductSet: undefined,
        productSets: [],
        isDialogOpen: false,
        name: '',
        phoneNumber: '',
        payMethod: 'card',
        contentWidth: (window.innerWidth < MAX_DESKTOP_WIDTH ? window.innerWidth : MAX_DESKTOP_WIDTH) / 100,
        authCode: '',
        isSentVerifiedCode: false,
    };

    const { isMobile, paymentType, token, direct, extension, recurring, queryParams, options, firstDate } = props;

    const [selectedProductSet, setSelectedProductSet] = useState<ProductSet | undefined>(initialStates.selectedProductSet);
    const [productSets, setProductSets] = useState<ProductSet[]>(initialStates.productSets);
    const [isDialogOpen, setDialogOpen] = useState(initialStates.isDialogOpen);
    const [name, setName] = useState(initialStates.name);
    const [phoneNumber, setPhoneNumber] = useState(initialStates.phoneNumber);
    const [payMethod, setPayMethod] = useState(initialStates.payMethod);
    const [contentWidth, setContentWidth] = useState(initialStates.contentWidth);
    const [authCode, setAuthCode] = useState(initialStates.authCode);
    const [isSentVerifiedCode, setIsSentVerifiedCode] = useState(initialStates.isSentVerifiedCode);

    const openPaymentDialog = (productSet: ProductSet) => {
        googleAnalyticsEvent('click', 'button', 'product_card');
        googleAnalyticsEvent('click', 'button', `product_card_${productSet.price}`);
        setSelectedProductSet(productSet);
        setDialogOpen(true);
    };

    const closePaymentDialog = () => {
        setSelectedProductSet(initialStates.selectedProductSet);
        setName(initialStates.name);
        setPhoneNumber(initialStates.phoneNumber);
        setPayMethod(initialStates.payMethod);
        setDialogOpen(initialStates.isDialogOpen);
        setAuthCode(initialStates.authCode);
        setIsSentVerifiedCode(initialStates.isSentVerifiedCode);
    };

    const onKeyDownOnPaymentDialog = (event) => {
        if (event.key === 'Escape') closePaymentDialog();
        if (event.key === 'Enter') purchase();
    };

    const onChangeName = (event) => {
        setName(event.target.value);
    };

    const onChangePhoneNumber = (event) => {
        setPhoneNumber(event.target.value);
    };

    const onChangeAuthCode = (event) => {
        setAuthCode(event.target.value);
    };

    const purchase = async () => {
        if (!extension) {
            const isVerified = await veryfyAuthCode();
            if (!isVerified) return alert('인증번호가 일치하지 않습니다.');
        }
        if (isNil(selectedProductSet)) return alert('상품을 선택해주세요.');
        if (!regExp.name.test(name)) return alert('이름을 다시 확인해주세요.');
        if (!regExp.phoneNumber.test(phoneNumber)) return alert('연락처를 다시 확인해주세요.');

        closePaymentDialog();
        purchaseProductSetWithIamport({
            productSet: selectedProductSet,
            payMethod,
            buyerName: name,
            buyerTel: phoneNumber,
            token,
            direct,
            extension,
            queryParams,
            startDate: firstDate ?? undefined,
        });
    };

    const getProductSets = (paymentType: number) => {
        ajax({
            type: 'GET',
            url: REST_URL + `/productSets?paymentType=${paymentType}`,
            success: function (result) {
                const productSetsOrigin = result.data.productSets.map((item) => {
                    item.price = item.products.reduce((acc: number, cur) => acc + cur.price * cur.quantity, 0);
                    item.originalPrice = item.products.reduce((acc: number, cur) => acc + cur.originalPrice * cur.quantity, 0);
                    return item;
                });
                setProductSets(productSetsOrigin);
            },
            contentType: 'application/json',
            dataType: 'json',
        });
    };

    const veryfyAuthCode = async (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            const body = {
                operationName: 'VerifyAuthCode',
                variables: { input: { code: authCode, phoneNumber } },
                query: `mutation VerifyAuthCode($input: VerifyAuthCodeInput!) {  verifyAuthCode(input: $input) {    result    __typename  }}`,
            };
            ajax({
                type: 'POST',
                url: GRAPHQL_URL,
                data: JSON.stringify(body),
                success: function (result) {
                    if (result.data.verifyAuthCode !== null) resolve(true);
                    resolve(false);
                },
                contentType: 'application/json',
                dataType: 'json',
            });
        });
    };

    useEffect(() => {
        const handleContentWidth = () => setContentWidth((window.innerWidth < MAX_DESKTOP_WIDTH ? window.innerWidth : MAX_DESKTOP_WIDTH) / 100);
        window.addEventListener('resize', handleContentWidth);
        return () => window.removeEventListener('resize', handleContentWidth);
    }, []);

    useEffect(() => {
        getProductSets(paymentType);
    }, [paymentType, recurring]);

    useEffect(() => {
        googleAnalyticsConfig('결제 페이지', '/payment');
    });

    return (
        <Wrapper>
            <SectionWrapper>
                <SectionImage alt="background_1.jpg" src={`/images/payment/${isMobile ? 'm_' : ''}background_1.jpg`} />
                <ProductSetList
                    isMobile={isMobile}
                    productSets={productSets}
                    contentWidth={contentWidth}
                    paymentType={paymentType}
                    options={options}
                    openPaymentDialog={openPaymentDialog}
                />
                <SectionImage alt="background_4.jpg" src={`/images/payment/${isMobile ? 'm_' : ''}background_4.jpg`} />
                <ProductRulesAccordion />
                <Footer />
                <PaymentSetDialog
                    productSet={selectedProductSet}
                    extension={extension}
                    phoneNumber={phoneNumber}
                    name={name}
                    isDialogOpen={isDialogOpen}
                    onChangeName={onChangeName}
                    onChangePhoneNumber={onChangePhoneNumber}
                    onKeyDownOnPaymentDialog={onKeyDownOnPaymentDialog}
                    closePaymentDialog={closePaymentDialog}
                    purchase={purchase}
                    authCode={authCode}
                    onChangeAuthCode={onChangeAuthCode}
                    isSentVerifiedCode={isSentVerifiedCode}
                    setIsSentVerifiedCode={setIsSentVerifiedCode}
                    paymentType={paymentType}
                    firstDate={firstDate}
                />
            </SectionWrapper>
        </Wrapper>
    );
}

export default PaymentSet;
