import React, { ReactNode } from 'react';

export interface IQuestionAnswerItem {
    question: string;
    answer: ReactNode;
}

export const questionListDefault: IQuestionAnswerItem[] = [
    {
        question: '개강일은 어떻게 되나요?',
        answer: (
            <>
                진단고사 실시 후 영업일 기준 2~3일동안 결과 분석 및 맞춤 커리큘럼이 작성되기 때문에 분석 완료 이후에 개강이 가능하므로{' '}
                <b>결제 후 안내 문자의 링크</b>를 통해 개강 날짜를 직접 선택하실 수 있습니다.
            </>
        ),
    },
    {
        question: '태블릿을 구매해야 될까요?',
        answer: (
            <>
                화상으로 진행되는 서비스가 아니기 때문에 학생 측에서 별도로 장비를 구매할 필요 없으며 <b>가정에서 사용 중인 PC 또는 태블릿</b>으로도 학습할 수
                있습니다.
            </>
        ),
    },
    {
        question: '수강기간에 상관없이 7일 내 환불인가요?',
        answer: (
            <>
                네 맞습니다. 3개월, 6개월, 1년 서비스를 결제하셔도 <b>개강 후 7일 이내</b> 환불 의사를 말씀해 주시면, <b>결제금액 100%를 환불</b>해 드립니다.
            </>
        ),
    },
];

export const questionListRefundTwoWeek: IQuestionAnswerItem[] = [
    {
        question: '개강일은 어떻게 되나요?',
        answer: (
            <>
                진단고사 실시 후 영업일 기준 2~3일동안 결과 분석 및 맞춤 커리큘럼이 작성되기 때문에 분석 완료 이후에 개강이 가능하므로{' '}
                <b>결제 후 안내 문자의 링크</b>를 통해 개강 날짜를 직접 선택하실 수 있습니다.
            </>
        ),
    },
    {
        question: '태블릿을 구매해야 될까요?',
        answer: (
            <>
                화상으로 진행되는 서비스가 아니기 때문에 학생 측에서 별도로 장비를 구매할 필요 없으며 <b>가정에서 사용 중인 PC 또는 태블릿</b>으로도 학습할 수
                있습니다.
            </>
        ),
    },
    {
        question: '수강기간에 상관없이 14일 내 환불인가요?',
        answer: (
            <>
                네 맞습니다. 3개월, 6개월, 1년 서비스를 결제하셔도 <b>개강 후 14일 이내</b> 환불 의사를 말씀해 주시면, <b>결제금액 100%를 환불</b>해 드립니다.
            </>
        ),
    },
];

export const questionListMiddleSchool: IQuestionAnswerItem[] = [
    {
        question: '개강일은 어떻게 되나요?',
        answer: (
            <>
                진단고사 실시 후 영업일 기준 2~3일동안 결과 분석 및 맞춤 커리큘럼이 작성되기 때문에 분석 완료 이후에 개강이 가능하므로{' '}
                <b>결제 후 안내 문자의 링크</b>를 통해 개강 날짜를 직접 선택하실 수 있습니다.
            </>
        ),
    },
    {
        question: '태블릿을 구매해야 될까요?',
        answer: (
            <>
                화상으로 진행되는 서비스가 아니기 때문에 학생 측에서 별도로 장비를 구매할 필요 없으며 <b>가정에서 사용 중인 PC 또는 태블릿</b>으로도 학습할 수
                있습니다.
            </>
        ),
    },
    {
        question: '학습시간은 어떻게 되나요?',
        answer: (
            <>
                밀당PT <b>학습시간은 15:30 - 23:00</b>이며 (마지막 출석 가능시간 21:30) <b>주 4회(월~목) 각 1시간 30분씩 희망시간</b>에 수업이 진행됩니다.
            </>
        ),
    },
    {
        question: '무조건 주 4회로 해야되나요?',
        answer: (
            <>
                밀당PT는 기본적으로 <b>주 4회 각 1시간30분씩</b>, 총 주 6시간 학습을 목표로 하고 있습니다만 학생 스케줄 상, 해당 시간이 어려울 경우{' '}
                <b>주 3회 각 2시간씩 / 주 2회 각 3시간씩</b> 유연하게 시간을 조정할 수 있습니다.
            </>
        ),
    },
    {
        question: '수강기간에 상관없이 7일 내 환불인가요?',
        answer: (
            <>
                네 맞습니다. 3개월, 6개월, 1년 서비스를 결제하셔도 <b>개강 후 7일 이내</b> 환불 의사를 말씀해 주시면, <b>결제금액 100%를 환불</b>해 드립니다.
            </>
        ),
    },
];

export const questionListHighSchool1: IQuestionAnswerItem[] = [
    {
        question: '개강일은 어떻게 되나요?',
        answer: (
            <>
                진단고사 실시 후 영업일 기준 2~3일동안 결과 분석 및 맞춤 커리큘럼이 작성되기 때문에 분석 완료 이후에 개강이 가능하므로{' '}
                <b>결제 후 안내 문자의 링크</b>를 통해 개강 날짜를 직접 선택하실 수 있습니다.
            </>
        ),
    },
    {
        question: '태블릿을 구매해야 될까요?',
        answer: (
            <>
                화상으로 진행되는 서비스가 아니기 때문에 학생 측에서 별도로 장비를 구매할 필요 없으며 <b>가정에서 사용 중인 PC 또는 태블릿</b>으로도 학습할 수
                있습니다.
            </>
        ),
    },
    {
        question: '학습시간은 어떻게 되나요?',
        answer: (
            <>
                밀당PT <b>학습시간은 15:30 - 23:00</b>이며 (마지막 출석 가능시간 21:30) <b>주 4회(월~목) 각 1시간 30분씩</b> 희망시간에 수업이 진행됩니다.
            </>
        ),
    },
    {
        question: '무조건 주 4회로 해야되나요?',
        answer: (
            <>
                밀당PT는 기본적으로 <b>주 4회 각 1시간 30분씩</b>, 총 주 6시간 학습을 목표로 하고 있습니다만 학생 스케줄 상 해당 시간이 어려울 경우,{' '}
                <b>주 3회 각 2시간 / 주 2회 각 2시간 30분~3시간씩</b> 유연하게 시간을 조정할 수 있습니다.
            </>
        ),
    },
    {
        question: '수강기간에 상관없이 7일 내 환불인가요?',
        answer: (
            <>
                네 맞습니다. 3개월, 6개월, 1년 서비스를 결제하셔도 <b>개강 후 7일 이내</b> 환불 의사를 말씀해 주시면, <b>결제금액 100%를 환불</b>해 드립니다.
            </>
        ),
    },
];

export const questionListHighSchool2: IQuestionAnswerItem[] = [
    {
        question: '개강일은 어떻게 되나요?',
        answer: (
            <>
                진단고사 실시 후 영업일 기준 2~3일동안 결과 분석 및 맞춤 커리큘럼이 작성되기 때문에 분석 완료 이후에 개강이 가능하므로{' '}
                <b>결제 후 안내 문자의 링크</b>를 통해 개강 날짜를 직접 선택하실 수 있습니다.
            </>
        ),
    },
    {
        question: '태블릿을 구매해야 될까요?',
        answer: (
            <>
                화상으로 진행되는 서비스가 아니기 때문에 학생 측에서 별도로 장비를 구매할 필요 없으며 <b>가정에서 사용 중인 PC 또는 태블릿</b>으로도 학습할 수
                있습니다.
            </>
        ),
    },
    {
        question: '학습시간은 어떻게 되나요?',
        answer: (
            <>
                밀당PT <b>학습시간은 15:30 - 23:00</b>이며 (마지막 출석 가능시간 21:30) <b>주 4회(월~목) 각 1시간 30분씩 희망시간</b>에 수업이 진행됩니다.
            </>
        ),
    },
    {
        question: '무조건 주 4회로 해야되나요?',
        answer: (
            <>
                밀당PT는 기본적으로 <b>주 4회 각 1시간30분씩</b>, 총 주 6시간 학습을 목표로 하고 있습니다만 학생 스케줄 상, 해당 시간이 어려울 경우{' '}
                <b>주 3회 각 2시간씩 / 주 2회 각 3시간씩</b> 유연하게 시간을 조정할 수 있습니다.
            </>
        ),
    },
    {
        question: '수강기간에 상관없이 7일 내 환불인가요?',
        answer: (
            <>
                네 맞습니다. 3개월, 6개월, 1년 서비스를 결제하셔도 <b>개강 후 7일 이내</b> 환불 의사를 말씀해 주시면, <b>결제금액 100%를 환불</b>해 드립니다.
            </>
        ),
    },
];

export const questionListHighSchool2Math: IQuestionAnswerItem[] = [
    {
        question: '개강일은 어떻게 되나요?',
        answer: (
            <>
                진단고사 실시 후 영업일 기준 2~3일동안 결과 분석 및 맞춤 커리큘럼이 작성되기 때문에 분석 완료 이후에 개강이 가능하므로{' '}
                <b>결제 후 안내 문자의 링크</b>를 통해 개강 날짜를 직접 선택하실 수 있습니다.
            </>
        ),
    },
    {
        question: '태블릿을 구매해야 될까요?',
        answer: (
            <>
                화상으로 진행되는 서비스가 아니기 때문에 학생 측에서 별도로 장비를 구매할 필요 없으며 <b>가정에서 사용 중인 PC 또는 태블릿</b>으로도 학습할 수
                있습니다.
            </>
        ),
    },
    {
        question: '학습시간은 어떻게 되나요?',
        answer: (
            <>
                밀당PT <b>학습시간은 15:30 - 23:00</b>이며 <b>주 4회(월~목) 2시간씩</b> 희망시간에 수업이 진행됩니다.
            </>
        ),
    },
    {
        question: '무조건 주 4회로 해야되나요?',
        answer: (
            <>
                밀당PT는 기본적으로 <b>주 4회 각 2시간씩</b>, 총 주 8시간 학습을 목표로 하고 있습니다만 학생 스케줄 상 해당 시간이 어려울 경우,{' '}
                <b>주 2회 각 4시간 등 담당선생님과 소통하여 스케줄 조정이 가능</b>합니다.
            </>
        ),
    },
    {
        question: '수강기간에 상관없이 7일 내 환불인가요?',
        answer: (
            <>
                네 맞습니다. 3개월, 6개월, 1년 서비스를 결제하셔도 <b>개강 후 7일 이내</b> 환불 의사를 말씀해 주시면, <b>결제금액 100%를 환불</b>해 드립니다.
            </>
        ),
    },
];

export const questionListHighSchool3: IQuestionAnswerItem[] = [
    {
        question: '개강일은 어떻게 되나요?',
        answer: (
            <>
                진단고사 실시 후 영업일 기준 2~3일동안 결과 분석 및 맞춤 커리큘럼이 작성되기 때문에 분석 완료 이후에 개강이 가능하므로{' '}
                <b>결제 후 안내 문자의 링크</b>를 통해 개강 날짜를 직접 선택하실 수 있습니다.
            </>
        ),
    },
    {
        question: '태블릿을 구매해야 될까요?',
        answer: (
            <>
                화상으로 진행되는 서비스가 아니기 때문에 학생 측에서 별도로 장비를 구매할 필요 없으며 <b>가정에서 사용 중인 PC 또는 태블릿</b>으로도 학습할 수
                있습니다.
            </>
        ),
    },
    {
        question: '학습시간은 어떻게 되나요?',
        answer: (
            <>
                밀당PT <b>학습시간은 15:30 - 23:00</b>이며 (마지막 출석 가능시간 21:30) <b>주 4회(월~목) 각 1시간 30분씩 희망시간</b>에 수업이 진행됩니다.
            </>
        ),
    },
    {
        question: '무조건 주 4회로 해야되나요?',
        answer: (
            <>
                밀당PT는 기본적으로 <b>월요일~목요일 주 4회 각 1시간30분씩 학습을 진행하고, 금요일에는 형성평가 1시간</b> 진행 하는 것을 목표로 하고 있습니다.
                다만 학생 스케줄 상, 해당 시간이 어려울 경우 유연하게 시간을 조정할 수 있습니다.
            </>
        ),
    },
    {
        question: '수강기간에 상관없이 7일 내 환불인가요?',
        answer: (
            <>
                네 맞습니다. 3개월, 6개월, 1년 서비스를 결제하셔도 <b>개강 후 7일 이내</b> 환불 의사를 말씀해 주시면, <b>결제금액 100%를 환불</b>해 드립니다.
            </>
        ),
    },
];

export const questionListHighSchool3Math: IQuestionAnswerItem[] = [
    {
        question: '개강일은 어떻게 되나요?',
        answer: (
            <>
                진단고사 실시 후 영업일 기준 2~3일동안 결과 분석 및 맞춤 커리큘럼이 작성되기 때문에 분석 완료 이후에 개강이 가능하므로{' '}
                <b>결제 후 안내 문자의 링크</b>를 통해 개강 날짜를 직접 선택하실 수 있습니다.
            </>
        ),
    },
    {
        question: '태블릿을 구매해야 될까요?',
        answer: (
            <>
                화상으로 진행되는 서비스가 아니기 때문에 학생 측에서 별도로 장비를 구매할 필요 없으며 <b>가정에서 사용 중인 PC 또는 태블릿</b>으로도 학습할 수
                있습니다.
            </>
        ),
    },
    {
        question: '학습시간은 어떻게 되나요?',
        answer: (
            <>
                밀당PT <b>학습시간은 15:30 - 23:00</b>이며 <b>주 4회(월~목) 2시간씩</b> (금요일 1시간 가량의 형성평가 제공) 희망시간에 수업이 진행됩니다.
            </>
        ),
    },
    {
        question: '무조건 주 4회로 해야되나요?',
        answer: (
            <>
                밀당PT는 기본적으로 <b>주 4회 각 2시간씩</b>, 총 주 8시간 학습(금요일 1시간 가량의 형성평가)을 목표로 하고 있습니다만 학생 스케줄 상 해당 시간이
                어려울 경우, <b>주 2회 각 4시간 등 담당선생님과 소통하여 스케줄 조정이 가능</b>합니다.
            </>
        ),
    },
    {
        question: '수강기간에 상관없이 7일 내 환불인가요?',
        answer: (
            <>
                네 맞습니다. 3개월, 6개월, 1년 서비스를 결제하셔도 <b>개강 후 7일 이내</b> 환불 의사를 말씀해 주시면, <b>결제금액 100%를 환불</b>해 드립니다.
            </>
        ),
    },
];
