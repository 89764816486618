import React, { useContext, useEffect, useRef, useState } from 'react';
import { regExp } from '../../utils/reg-exp';
import { head, isEmpty, isNil } from '@fxts/core';
import { PurchaseDirectInfoContext, PurchaseDirectInfoContextStore } from '../../contexts/purchase-direct.context';
import { DEVICE, GRAPHQL_URL, REST_URL } from '../../constants/constants';
import { googleAnalyticsConfig, googleAnalyticsEvent } from '../../utils/google-analytics';
import { PurchaseConfigure, PurchaseType } from '../../constants/purchase-page';
import { ThemeProvider } from '@material-ui/core';
import { theme } from '../../components/common/Theme';
import { PurchaseModuleSection } from '../../components/purchase/PurchaseModuleSection';
import { PurchaseModuleDrawer } from '../../components/purchase/PurchaseModuleDrawer';
import { PurchaseModuleBottomPannel } from '../../components/purchase/PurchaseModuleBottomPannel';
import { PurchaseModuleCard } from '../../components/purchase/PurchaseModuleCard';
import { ProductRulesAccordion } from '../../components/purchase/ProductRulesAccordion';
import { purchaseWithIamport } from '../../utils/purchase';
import { ajax } from '../../utils/jQuery';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { PurcahseMultiplePaymentIntroductionSection } from '../../components/purchase/section/PurcahseMultiplePaymentIntroductionSection';
import { PurchaseHeader } from '../../components/purchase/PurchaseHeader';
import { PurchaseFAQSection } from '../../components/purchase/section/PurchaseFAQSection';
import { PurchaseProcessSection } from '../../components/purchase/section/PurchaseProcessSection';
import { PurchasePersonalTeachingSection } from '../../components/purchase/section/PurchasePersonalTeachingSection';
import { PurchaseBenefitSection } from '../../components/purchase/section/PurchaseBenefitSection';
import { PurchaseAdvantageSection } from '../../components/purchase/section/PurchaseAdvantageSection';
import { PurchaseStrategySection } from '../../components/purchase/section/PurchaseStrategySection';
import { Footer } from '../../components/common/Footer';
import { DafaultPage } from '../Default';

interface PurchaseDirectProps {
    purchaseType: PurchaseType | string;
    token?: string;
    direct?: boolean;
    extension?: boolean;
    queryParams?: any[];
    firstDate?: Date | null;
    billingDay?: number | null;
    needFirstDate?: boolean;
}

export const PurchaseDirect = (props: PurchaseDirectProps) => {
    const { purchaseType, token, direct, extension, queryParams, firstDate, billingDay, needFirstDate = false } = props;

    useEffect(() => {
        googleAnalyticsConfig('직접 구매 페이지', '/purchase');
    }, []);

    if (needFirstDate === true && firstDate === null) return <DafaultPage isLoading={false}>개강일이 지정되지 않았습니다.</DafaultPage>;
    if (typeof purchaseType === 'string' && !Object.keys(PurchaseType).includes(purchaseType)) {
        return <DafaultPage isLoading={false}>타입이 지정되지 않았습니다.</DafaultPage>;
    }
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <PurchaseDirectInfoContext>
                <PurchaseDirectContent
                    purchaseType={purchaseType as PurchaseType}
                    token={token}
                    direct={direct}
                    extension={extension}
                    queryParams={queryParams}
                    firstDate={firstDate}
                    billingDay={billingDay}
                />
            </PurchaseDirectInfoContext>
        </MuiPickersUtilsProvider>
    );
};

interface PurchaseDirectContentProps {
    purchaseType: PurchaseType;
    token?: string;
    direct?: boolean;
    extension?: boolean;
    queryParams?: any[];
    firstDate?: Date | null;
    billingDay?: number | null;
}

const PurchaseDirectContent = (props: PurchaseDirectContentProps) => {
    const { purchaseType, token, direct, extension, queryParams, firstDate, billingDay } = props;
    const {
        device,
        setProducts,
        setSelectedProduct,
        name,
        phoneNumber,
        payMethod,
        authCode,
        amount,
        setAmount,
        firstDateOfClass,
        monthlyBillingDay,
        setPurchaseConfigure,
        productListOnCart,
        setProductListOnCart,
        setFirstDateOfClass,
        setMonthlyBillingDay,
        setFirstDateOfClassDisabled,
        setMonthlyBillingDayDisabled,
    } = useContext(PurchaseDirectInfoContextStore);

    const configure = PurchaseConfigure[purchaseType];
    const paymentType = configure.paymentType;
    const isMultiplePayment = configure.isMultiplePayment;
    const needTablet = configure.needTablet;
    const withTablet =
        purchaseType === PurchaseType.ADDICTIVE_MIDDLE_MATH_4 ||
        purchaseType === PurchaseType.ADDICTIVE_MIDDLE_ENGLISH_4 ||
        purchaseType === PurchaseType.ADDICTIVE_TABLET ||
        purchaseType === PurchaseType.ADDICTIVE_MONTHLY_BILLING;
    const material = PurchaseConfigure[purchaseType];

    const { scrollY } = useContext(PurchaseDirectInfoContextStore);
    const FAQSectionRef = useRef(null);
    const [isShowDrawer, setIsShowDrawer] = useState(false);

    const veryfyAuthCode = async () => {
        return new Promise((resolve) => {
            const body = {
                operationName: 'VerifyAuthCode',
                variables: { input: { code: authCode, phoneNumber } },
                query: `mutation VerifyAuthCode($input: VerifyAuthCodeInput!) {  verifyAuthCode(input: $input) {    result    __typename  }}`,
            };
            (window as any).$.ajax({
                type: 'POST',
                url: GRAPHQL_URL,
                data: JSON.stringify(body),
                success: function (result) {
                    if (result.data.verifyAuthCode !== null) resolve(true);
                    resolve(false);
                },
                contentType: 'application/json',
                dataType: 'json',
            });
        });
    };

    const purchase = async () => {
        const isVerified = await veryfyAuthCode();
        if (!isVerified) return alert('인증번호가 일치하지 않습니다.');
        if (!regExp.name.test(name)) return alert('이름을 다시 확인해주세요.');
        if (!regExp.phoneNumber.test(phoneNumber)) return alert('연락처를 다시 확인해주세요.');
        if (isEmpty(productListOnCart)) return alert('한개 이상의 상품을 선택해주세요.');

        googleAnalyticsEvent('click', 'button', 'direct_purchase_button');
        purchaseWithIamport({
            products: productListOnCart,
            amount,
            payMethod,
            buyerName: name,
            buyerTel: phoneNumber,
            token,
            direct,
            extension,
            queryParams,
            purchaseType,
            monthlyBillingDay,
            startDate: firstDateOfClass ?? undefined,
        });
    };

    useEffect(() => {
        const configure = PurchaseConfigure[purchaseType];
        setPurchaseConfigure(configure);
        if (!isNil(firstDate)) {
            setFirstDateOfClassDisabled(true);
            setFirstDateOfClass(firstDate);
        }
        if (!isNil(billingDay)) {
            setMonthlyBillingDayDisabled(true);
            setMonthlyBillingDay(billingDay);
        }
    }, [purchaseType]);

    useEffect(() => {
        function getProducts(paymentType) {
            ajax({
                type: 'GET',
                url: REST_URL + `/products?productType=1&paymentType=${paymentType}`,
                success: function (result) {
                    const { products } = result.data;
                    const productList = products.map((product) => {
                        if (product.curriculumType === 1) {
                            product.group = '주간 집중관리';
                        }
                        if (product.curriculumType === 2) {
                            product.group = '심야 집중관리';
                        }
                        return product;
                    });

                    const defaultProduct: any = head(productList);

                    setProducts(productList);
                    setSelectedProduct(defaultProduct);
                    if (!isMultiplePayment) {
                        setProductListOnCart([defaultProduct]);
                        setAmount(defaultProduct.price);
                    }
                },
                contentType: 'application/json',
                dataType: 'json',
            });
        }

        getProducts(paymentType);
    }, [paymentType]);

    useEffect(() => {
        const productListSectionTop = (FAQSectionRef?.current as any)?.offsetTop;
        if (scrollY > productListSectionTop) setIsShowDrawer(true);
        if (scrollY <= productListSectionTop) setIsShowDrawer(false);
    }, [scrollY]);

    return (
        <ThemeProvider theme={theme}>
            <PurchaseHeader material={material} />
            {device === DEVICE.MOBILE && <PurchaseModuleSection purchaseType={purchaseType} purchase={purchase} />}
            <PurcahseMultiplePaymentIntroductionSection isMultiplePayment={isMultiplePayment} needTablet={needTablet} />
            {material.showSections.section1 && (
                <div ref={FAQSectionRef}>
                    <PurchaseFAQSection material={material} />
                </div>
            )}
            {material.showSections.section2 && <PurchaseProcessSection material={material} />}
            {material.showSections.section3 && <PurchasePersonalTeachingSection material={material} />}
            {material.showSections.section4 && <PurchaseBenefitSection material={material} />}
            {material.showSections.section5 && <PurchaseAdvantageSection material={material} />}
            {material.showSections.section6 && <PurchaseStrategySection material={material} />}
            <ProductRulesAccordion withTablet={withTablet} />
            <Footer />
            {device === DEVICE.MOBILE && isShowDrawer && <PurchaseModuleDrawer purchaseType={purchaseType} purchase={purchase} isExpandable={true} />}
            {device === DEVICE.TABLET && <PurchaseModuleBottomPannel purchaseType={purchaseType} purchase={purchase} />}
            {device === DEVICE.DESKTOP && <PurchaseModuleCard purchase={purchase} />}
        </ThemeProvider>
    );
};
