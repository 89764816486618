import React, { useEffect, useState } from 'react';
import { PaymentDirectResultViewer } from '../../../components/direct/result/PaymentDirectResultComponentViewer';
import { MAX_RETRY_COUNT, PaymentResultStatus, REST_URL } from '../../../constants/constants';
import { ajax } from '../../../utils/jQuery';
import { isEmpty, isUndefined, size } from '@fxts/core';
import { PaymentData } from '../../../utils/payment';

interface PaymentDirectResultProps {
    impUid: string;
    merchantUid: string;
    impSuccess: string | boolean;
    purchaseType: string;
    errorMsg?: string;
}

function PaymentDirectResult(props: PaymentDirectResultProps) {
    const { impUid, merchantUid, impSuccess, purchaseType, errorMsg = '' } = props;
    const [payments, setPayments] = useState<PaymentData[]>([]);
    const [billingKeyPayment, setBillingKeyPayment] = useState(null);
    const [isProductSet, setIsProductSet] = useState(false);
    const [status, setStatus] = useState(PaymentResultStatus.PENDING);
    let retryCount = 0;

    useEffect(() => {
        const changeStatusWithPayments = (payments: PaymentData[]) => {
            if (isEmpty(payments)) {
                changeStatusPendingAndRetry();
                return;
            }
            setStatus(PaymentResultStatus.SUCCEED);
            return;
        };

        const changeStatusPendingAndRetry = () => {
            setStatus(PaymentResultStatus.PENDING);

            if (retryCount < MAX_RETRY_COUNT) {
                retryCount++;
                setTimeout(() => getPaymentInfo(), 2000);
            } else {
                setStatus(PaymentResultStatus.INVALID);
            }
        };

        const setPaymentsCompleted = (paymentsData: PaymentData[]) => {
            typeof (window as any).gtag === 'function' &&
                (window as any).gtag('config', 'UA-100968789-9', {
                    page_title: '결제 결과 페이지',
                    page_path: '/payment_result',
                });

            setPayments(paymentsData);
            changeStatusWithPayments(paymentsData);
        };

        const getPaymentInfo = () => {
            ajax({
                type: 'GET',
                url: REST_URL + `/payments/multiple?impUid=${impUid}`,
                success: function (result: any) {
                    const billingKeyPayment = result.data.billingKeyPayment;
                    const payments: PaymentData[] = result.data.payments;
                    if (isUndefined(billingKeyPayment)) return setPaymentsCompleted(payments);

                    const paymentCount = size(payments);
                    const paymentCountShouldPaid = size(billingKeyPayment.customData.productIds ?? []);
                    const paymentCompleted = paymentCount >= paymentCountShouldPaid;
                    const paymentFailed = paymentCount > 0 && payments.some((payment) => payment.status !== 'paid');

                    const isPaymentWillUpdated = !paymentCompleted && !paymentFailed;

                    if (isPaymentWillUpdated) return changeStatusPendingAndRetry();

                    if (billingKeyPayment.customData.productCompositionType === 'PRODUCT_SET') setIsProductSet(true);
                    setBillingKeyPayment(billingKeyPayment);
                    setPaymentsCompleted(payments);
                },
                complete: function (result: any) {
                    if (result.status !== 200) changeStatusPendingAndRetry();
                },
                contentType: 'application/json',
                dataType: 'json',
            });
        };

        const init = (impSuccess: boolean | string) => {
            if (impUid === 'null' || merchantUid === 'null') return setStatus(PaymentResultStatus.INVALID);
            setStatus(PaymentResultStatus.PENDING);
            if (impSuccess === true) return getPaymentInfo();
            if (impSuccess === false) {
                return setStatus(PaymentResultStatus.INVALID);
            }
            setStatus(PaymentResultStatus.INVALID);
        };

        init(impSuccess);
    }, [impSuccess, impUid, merchantUid, retryCount]);

    return (
        <PaymentDirectResultViewer
            payments={payments}
            status={status}
            purchaseType={purchaseType}
            errorMsg={errorMsg}
            isProductSet={isProductSet}
            billingKeyPayment={billingKeyPayment}
        />
    );
}

export default PaymentDirectResult;
