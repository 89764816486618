import React, { useContext, useEffect, useRef } from 'react';
import { PaymentDirectInfoContext, PaymentDirectInfoContextStore } from '../../contexts/payment-direct.context';
import { googleAnalyticsConfig, googleAnalyticsEvent } from '../../utils/google-analytics';
import { Footer } from '../../components/common/Footer';
import styled from 'styled-components';
import { moveToPurchasePage } from '../../utils/move-to-purchase-page';

const MobileImage = styled.img`
    display: block;
    width: 100%;
`;

const DesktopImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    background: #f8f8fa;
`;

const DesktopPromotionImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    background: #0066d0;
`;

export const PaymentDirectElementary = (props) => {
    const { paymentPage, isPromotion } = props;
    useEffect(() => {
        googleAnalyticsConfig('직접 결제 페이지', '/direct');
    }, []);

    return (
        <PaymentDirectInfoContext>
            <PaymentDirectElementaryContent paymentPage={paymentPage} isPromotion={isPromotion} />
        </PaymentDirectInfoContext>
    );
};

const PaymentDirectElementaryContent = (props) => {
    const { paymentPage, isPromotion } = props;
    const { isMobile, scrollY, readsProductListSection, setReadsProductListSection, desktopContentWidth } = useContext(PaymentDirectInfoContextStore);
    const productListSectionRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (readsProductListSection) return;
        const productListSectionTop = productListSectionRef?.current?.offsetTop ?? 0;
        if (scrollY <= productListSectionTop) return;

        setReadsProductListSection(true);
        googleAnalyticsEvent('scroll', 'scroll', 'productionList');
    }, [readsProductListSection, scrollY, setReadsProductListSection]);
    if (isMobile) {
        return (
            <>
                {isPromotion && <MobileImage src="/images/payment-direct/main/mobile/0-elementary.png" alt="0-elementary" />}

                <MobileImage src="/images/payment-direct/main/mobile/2-elementary.png" alt="2-elementary" />
                <div ref={productListSectionRef}>
                    <PaymentElementaryCardListMobile paymentPage={paymentPage} />
                </div>
                <Footer />
            </>
        );
    } else {
        return (
            <>
                {isPromotion && (
                    <DesktopPromotionImageWrapper>
                        <img src="/images/payment-direct/main/desktop/0-elementary.png" alt="0-elementary" width={desktopContentWidth} />
                    </DesktopPromotionImageWrapper>
                )}
                <DesktopImageWrapper>
                    <img src="/images/payment-direct/main/desktop/2-elementary.png" alt="2-elementary" width={desktopContentWidth} />
                </DesktopImageWrapper>
                <div ref={productListSectionRef}>
                    <PaymentElementaryCardListDesktop paymentPage={paymentPage} />
                </div>
                <Footer />
            </>
        );
    }
};

const PaymentElementaryCardListDesktop = (props) => {
    const { paymentPage } = props;
    const { desktopContentWidth } = useContext(PaymentDirectInfoContextStore);

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#FFFFFF' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 40, width: desktopContentWidth, padding: '70px 0' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                        <div
                            style={{
                                fontFamily: 'Pretendard',
                                fontSize: '40px',
                                fontWeight: 600,
                                lineHeight: '48px',
                                letterSpacing: '-0.03em',
                                textAlign: 'center',
                                color: '#A8A8A8',
                            }}
                        >
                            초등학생도 이제는 1:1 관리
                        </div>
                        <div
                            style={{
                                fontFamily: 'Pretendard',
                                fontSize: '65px',
                                fontWeight: 800,
                                lineHeight: '80px',
                                letterSpacing: '-0.09em',
                                textAlign: 'center',
                                color: '#000000',
                            }}
                        >
                            초등학생 PT
                        </div>
                    </div>
                    {paymentPage.purchases[0] && (
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '40px', width: '100%' }}>
                            <div style={{ flex: 1 }}>
                                <img
                                    onClick={() => moveToPurchasePage(paymentPage, 0)}
                                    style={{ display: 'block', cursor: 'pointer', width: '100%' }}
                                    src="/images/payment-direct/main/desktop/elementary-english.png"
                                    alt="elementary-english"
                                />
                            </div>
                        </div>
                    )}
                    {paymentPage.purchases[1] && (
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '40px', width: '100%' }}>
                            <div style={{ flex: 1 }}>
                                <img
                                    onClick={() => moveToPurchasePage(paymentPage, 1)}
                                    style={{ display: 'block', cursor: 'pointer', width: '100%' }}
                                    src="/images/payment-direct/main/desktop/elementary-math.png"
                                    alt="elementary-math"
                                />
                            </div>
                        </div>
                    )}
                    {/* {paymentPage.purchases[2] && (
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '40px', width: '100%' }}>
                            <div style={{ flex: 1 }}>
                                <img
                                    onClick={() => moveToPurchasePage(paymentPage, 2)}
                                    style={{ display: 'block', cursor: 'pointer', width: '100%' }}
                                    src="/images/payment-direct/main/desktop/elementary-korean.png"
                                    alt="elementary-korean"
                                />
                            </div>
                        </div>
                    )} */}
                </div>
            </div>
        </div>
    );
};

const PaymentElementaryCardListMobile = (props) => {
    const { paymentPage } = props;

    return (
        <div>
            <div style={{ display: 'block', width: '100%', flexDirection: 'column', alignItems: 'center', padding: '11vw 5vw', backgroundColor: '#FFFFFF' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                    <div
                        style={{
                            fontFamily: 'Pretendard',
                            fontSize: '5vw',
                            fontWeight: 600,
                            lineHeight: '6.2vw',
                            letterSpacing: '-0.03em',
                            textAlign: 'left',
                            color: '#A8A8A8',
                        }}
                    >
                        초등학생도 이제는 1:1 관리
                    </div>
                    <div
                        style={{
                            fontFamily: 'Pretendard',
                            fontSize: '9.5vw',
                            fontWeight: 800,
                            lineHeight: '11vw',
                            letterSpacing: '-0.03em',
                            textAlign: 'left',
                            color: '#000000',
                        }}
                    >
                        초등학생 PT
                    </div>
                </div>
                {paymentPage.purchases[0] && (
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '5vw' }}>
                        <img
                            onClick={() => moveToPurchasePage(paymentPage, 0)}
                            style={{ display: 'block', width: '99%' }}
                            src="/images/payment-direct/main/mobile/elementary-english.png"
                            alt="elementary-english"
                        />
                    </div>
                )}
                {paymentPage.purchases[1] && (
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '5vw' }}>
                        <img
                            onClick={() => moveToPurchasePage(paymentPage, 1)}
                            style={{ display: 'block', width: '99%' }}
                            src="/images/payment-direct/main/mobile/elementary-math.png"
                            alt="elementary-math"
                        />
                    </div>
                )}
                {/* {paymentPage.purchases[2] && (
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '5vw' }}>
                        <img
                            onClick={() => moveToPurchasePage(paymentPage, 2)}
                            style={{ display: 'block', width: '99%' }}
                            src="/images/payment-direct/main/mobile/elementary-korean.png"
                            alt="elementary-korean"
                        />
                    </div>
                )} */}
            </div>
        </div>
    );
};
